<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: tableEmptyState.vue
Description: This file contains UI components show when there is no data to display in the data table
-->
<template>
  <div class="table-empty-state">
    <!-- <span>
      <img class="no-data__img" src="@/assets/alerts/no-schedule-alert.svg" />
    </span>-->
    <span class="no-data__text-wrapper">
      <slot>
        <el-tag class="no-data__text">
          <p>{{ $t("PM_no_sch_found") }}</p>
        </el-tag>
      </slot>
    </span>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.table-empty-state {
  .no-data__text-wrapper p {
    @include responsiveProperty(
      font-size,
      $app_font_size_v_medium,
      $app_font_size_small,
      $app_font_size_1
    );
  }
}
</style>
