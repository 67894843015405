<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondScheduleModeCollapseItem.vue
Description: This file contains UI components used to display collapsable ponds in schedule mode in PondMother Schedules page
-->
<template>
  <el-row>
    <el-row>
      <el-row type="flex" justify="center">
        <el-alert
          :title="$t('Comn_alerts')"
          type="error"
          @close="ehm__handleAlertClose"
          v-if="ehm__unhandledErrorMessage !== ''"
          show-icon
          >{{ ehm__unhandledErrorMessage }}</el-alert
        >
      </el-row>
    </el-row>
    <el-row>
      <el-col class="add-btn-container hidden-lg-and-up" :span="24" :md="24">
        <el-row type="flex" justify="end" v-if="!hideAddSaveBtnsAtPondLevel">
          <er-button
            size="mini"
            btnType="add"
            icon="el-icon-circle-plus-outline"
            :disabled="disabledAddScheds()"
            :showLabel="true"
            @click="handleAddTS"
          ></er-button>

          <er-button
            size="mini"
            type="danger"
            :disabled="isReadOnly"
            @click="handlePondClearFutureSchedules($event, pondId)"
          >
            {{ $t("PM_clr_future_sch") }}
          </er-button>
        </el-row>
      </el-col>
      <el-col :span="22">
        <el-form
          class="mode-collapse-item"
          v-loading="loading"
          element-loading-background="white"
        >
          <el-table
            :data="tableData"
            size="mini"
            :indent="10"
            :border="false"
            :stripe="false"
            class="card-row-table"
            header-row-class-name="card-row-table__header-row"
            :row-class-name="functionPondTSTableRowClassName"
          >
            <template slot="empty">
              <table-empty-state></table-empty-state>
            </template>
            <el-table-column
              v-if="mode === 'HYBRID'"
              :width="tableColumns.mode_g.width"
              :label="tableColumns.mode_g.label"
              header-align="center"
              align="left"
            >
              <!-- v-if="mode === 'HYBRID'" -->
              <template slot-scope="scope">
                <er-select
                  class="mode_dropdown_alignment"
                  :value="modeValue(scope.row.mode !== 'HYBRID' ? scope.row.mode : '')"
                  :placeholder="$t('select_mode')"
                  size="mini"
                  popper-class="mode-dropdown"
                  :disabled="disableMode(scope.row)"
                  @focus="handleFocusOnPondTS($event, scope.row, 'TIME_PICKER')"
                  @change="handleChangeInput($event, scope.row, 'mode_g')"
                >
                  <el-option
                    v-for="value in pondMotherModesOfOperations"
                    :label="$t(value.lang_key)"
                    :key="value.mode"
                    :value="value.mode"
                  >
                    <svg
                    v-if="value.mode !== 'HYBRID'"
                    class="list-item__color-swatch_pm"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="4" cy="4" r="3" :fill="modeColor(value.mode)">
                    </circle>
                  </svg><span class="option_span">{{ $t(value.lang_key) }}</span>
                </el-option>
                  <template slot="prefix">
                    <svg
                    v-if="scope.row.mode !== 'HYBRID'"
                      class="list-item__color-swatch_pm"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="4" cy="4" r="3" :fill="modeColor(modeValue(scope.row.mode))">
                      </circle>
                    </svg>
                  </template>
                </er-select>
              </template>
            </el-table-column>
            <el-table-column
              :width="tableColumns.s_time.width"
              header-align="center"
              align="center"
              :label="tableColumns.s_time.label"
            >
              <template slot-scope="scope">
                <el-time-picker
                  :clearable="false"
                  prefix-icon=" "
                  v-model="scope.row.s_time"
                  size="mini"
                  value-format="HH:mm"
                  format="HH:mm"
                  :picker-options="fromTimePickerOptions"
                  :disabled="disableFromTime(scope.row)"
                  @focus="handleFocusOnPondTS($event, scope.row, 'TIME_PICKER')"
                  @input="handleChangeTPicker($event, scope.row, 'from_time')"
                ></el-time-picker>
              </template>
            </el-table-column>
            <el-table-column
              :width="tableColumns.e_time.width"
              header-align="center"
              align="center"
              :label="tableColumns.e_time.label"
            >
              <template slot-scope="scope">
                <el-time-picker
                  :clearable="false"
                  prefix-icon=" "
                  v-model="scope.row.e_time"
                  size="mini"
                  value-format="HH:mm"
                  format="HH:mm"
                  :picker-options="toTimePickerOptions"
                  :disabled="disableToTime(scope.row)"
                  @focus="handleFocusOnPondTS($event, scope.row, 'TIME_PICKER')"
                  @input="handleChangeTPicker($event, scope.row, 'to_time')"
                ></el-time-picker>
              </template>
            </el-table-column>
            <el-table-column
              :width="tableColumns.total_feed_kgs.width"
              header-align="center"
              align="center"
              :label="tableColumns.total_feed_kgs.label"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  size="mini"
                  title
                  v-model="scope.row.feed"
                  :precision="2"
                  :min="0"
                  :autofocus="false"
                  :disabled="disableTotalFeed(scope.row)"
                  :controls="false"
                  @focus="handleFocusOnPondTS($event, scope.row, 'NUMBER')"
                  @change="
                    handleChangeInput($event, scope.row, 'total_feed_kgs')
                  "
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              :width="tableColumns.ocf_g.width"
              header-align="center"
              align="center"
              :label="tableColumns.ocf_g.label"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  size="mini"
                  title
                  v-model="scope.row.ocf"
                  :min="200"
                  :step="50"
                  :precision="0"
                  :disabled="disableOCF(scope.row)"
                  :controls="false"
                  @focus="handleFocusOnPondTS($event, scope.row, 'NUMBER')"
                  @change="handleChangeInput($event, scope.row, 'ocf_g')"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              v-if="upm__showFeedGapField"
              :width="tableColumns.feed_gap.width"
              header-align="center"
              align="center"
              :label="tableColumns.feed_gap.label"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  size="mini"
                  title
                  v-model="scope.row.feed_gap"
                  :precision="2"
                  :min="0"
                  :controls="false"
                  :disabled="disableFeedGap(scope.row)"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              v-if="mode === 'HYBRID'"
              :width="tableColumns.feeding_level_g.width"
              :label="tableColumns.feeding_level_g.label"
              header-align="center"
              align="left"
            >
              <!-- v-if="mode === 'HYBRID'" -->
              <template slot-scope="scope">
                <er-select
                  :value="feedingValue(scope.row)"
                  class="mode_dropdown_alignment"
                  :placeholder="$t('Comn_feed_level')"
                  size="mini"
                  popper-class="feeding-level-dropdown"
                  :disabled="disableFeeding(scope.row)"
                  @change="handleChangeInput($event, scope.row, 'feeding_level_g')"
                >
                  <el-option
                    v-for="value in feedingLevelData"
                    :label="$t(value.label)"
                    :key="value.value"
                    :value="value.value"
                  ></el-option>
                </er-select>
              </template>
            </el-table-column>
            <el-table-column
              :width="tableColumns.dispensed_feed.width"
              header-align="center"
              align="center"
              :label="tableColumns.dispensed_feed.label"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  size="mini"
                  title
                  v-model="scope.row.dispensed_feed"
                  :precision="2"
                  :min="0"
                  :controls="false"
                  :disabled="true"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              :width="tableColumns.actions.width"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <el-row type="flex" align="middle">
                  <template v-if="!hidePondStatusActionBtns(scope.row) ||
                      !validScheduleCheckData[scope.row.bk_id]">
                    <er-button
                      v-if="scope.row.ui_status === POND_TS_STATUS.RUNNING"
                      size="mini"
                      btnType="pause"
                      :showIcon="true"
                      class="btn-actions"
                      :disabled="isReadOnly"
                      @click="
                        handleStatusChange(
                          $event,
                          scope.$index,
                          scope.row,
                          POND_TS_STATUS.PAUSED
                        )
                      "
                    ></er-button>
                    <er-button
                      v-if="scope.row.ui_status === POND_TS_STATUS.PAUSED"
                      size="mini"
                      btnType="play"
                      :showIcon="true"
                      class="btn-actions"
                      :disabled="isReadOnly"
                      @click="
                        handleStatusChange(
                          $event,
                          scope.$index,
                          scope.row,
                          POND_TS_STATUS.RUNNING
                        )
                      "
                    ></er-button>
                    <er-button
                      v-if="
                        [POND_TS_STATUS.RUNNING, POND_TS_STATUS.PAUSED].indexOf(
                          scope.row.ui_status
                        ) > -1
                      "
                      size="mini"
                      class="btn-actions"
                      btnType="stop"
                      :showIcon="true"
                      :disabled="isReadOnly"
                      @click="
                        handleStatusChange(
                          $event,
                          scope.$index,
                          scope.row,
                          POND_TS_STATUS.STOPPED
                        )
                      "
                    ></er-button>
                    <el-divider
                      direction="vertical"
                      v-if="!hidePondDivider(scope.row)"
                    ></el-divider>
                  </template>
                  <!-- schedule creation controls -->
                  <er-button
                    class="btn-actions"
                    size="mini"
                    icon="el-icon-minus"
                    v-show="!isPondTSCompleted(scope.row)"
                    :disabled="disableDelTS(scope.row)"
                    @click="handleDeleteTS($event, scope.$index, scope.row)"
                  ></er-button>
                </el-row>
              </template>
            </el-table-column>
          </el-table>
          <el-row
            type="flex"
            class="save-btn-container"
            v-if="!hideAddSaveBtnsAtPondLevel"
          >
            <er-button
              size="mini"
              btnType="save"
              :showLabel="true"
              :showIcon="true"
              :disabled="isReadOnly"
              @click="handleSaveSchedule"
            ></er-button>
          </el-row>
        </el-form>
      </el-col>
      <el-col
        class="add-btn-container hidden-md-and-down"
        :span="2"
      >
        <el-row
          type="flex"
          justify="space-between"
          v-if="!hideAddSaveBtnsAtPondLevel"
        >
          <er-button
            size="mini"
            btnType="add"
            icon="el-icon-circle-plus-outline"
            :disabled="disabledAddScheds()"
            :showLabel="false"
            @click="handleAddTS"
          ></er-button>
          <er-button
            :title="$t('PM_clr_future_sch')"
            size="mini"
            type="danger"
            icon="el-icon-remove-outline"
            :disabled="isReadOnly"
            :showLabel="false"
            @click="handlePondClearFutureSchedules($event, pondId)"
          >
          <!-- {{ $t("clear") }} -->
            <!-- {{ $t("PM_clr_future_sch") }} -->
          </er-button>
        </el-row>
      </el-col>
    </el-row>
  </el-row>
</template>
<script>
import pmSchedulesMixin from "@/mixins/pmSchedulesMixin.js";
import { mapGetters, mapActions, mapMutations } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { POND_TS_STATUS } from "@/constants/schedule";
import PondMother from "@/model/pondmother";
import dateUtils from "@/utils/dateUtils";

// const DAY_TIME_IN_MS = 86400000;
// const MAX_DAY_LIMIT = 86412345;
export default {
  mixins: [pmSchedulesMixin, errorHandlerMixin],
  props: ["pond", "mode"],
  data: function () {
    return {
      pastPickedTime: [],
      fromTimePickerOptions: {
        format: "HH:mm"
      },
      toTimePickerOptions: {
        format: "HH:mm"
      },
      ehm__errMessagesObject: {},
      canAddASchedule: 0,
      deletedTSBkIds: [],
      loading: false,
      disableAddButton: false,
      POND_TS_STATUS,
      feedingLevel: [
        {
          label: "Comn_normal",
          value: 0
        },
        {
          label: "Comn_economical",
          value: 1
        },
        {
          label: "Comn_aggressive",
          value: 2
        },
      ],
      feedingLevelSuper: [
        {
          label: "Comn_normal",
          value: 0
        },
        {
          label: "Comn_economical",
          value: 1
        },
        {
          label: "Comn_aggressive",
          value: 2
        },
        {
          label: "Comn_super_aggressive",
          value: 3
        }
      ],
      cmCurrTimeSecs: dateUtils.getCurrTimeSecsInGivenTZ,
      validScheduleCheckData: {}
    };
  },

  computed: {
    ...mapGetters("pondMotherSchedules", {
      getSelectedDay: "getSelectedDay",
      getPondTSTableData: "getPondTSTableData",
      getDateRange: "getDateRange",
      getRemainingTSToPondId: "getRemainingTSToPondId",
      getSelectedPondStIdsToPmIds: "getSelectedPondStIdsToPmIds",
      isCurrentDay: "isCurrentDay",
    }),
    ...mapGetters("user", {
      getUserTimeZone: "getUserTimeZone",
      getUserId: "getUserId"
    }),
    feedingLevelData() {
      if (this.getUserId === "5f74984140f3e1412d1de9cd" ||
          this.getUserId === "5e5cfb322c599d13d25b607e" ||
          this.getUserId === "5f098445be50fddb616e47c9") {
        return this.feedingLevelSuper;
      } else {
        return this.feedingLevel;
      }
    },
    modeValue() {
      return (mode) => {
        return mode === 'FARMER' ? 'SCHEDULE' : mode === 'SHRIMP_TALK' ? 'AUTOMATIC' : mode === 'MANUAL' ? 'BASIC' : mode;
      }
    },
    modeColor() {
      return (mode) => {
        return PondMother.generateColorForMode(mode).color;
      }
    },

    pondMotherModesOfOperations() {
      return {
        AUTOMATIC: { mode: 'AUTOMATIC', lang_key: 'PM_automatic' },
        SCHEDULE: { mode: "SCHEDULE", lang_key: "Comn_schedule" },
        // BASIC: { mode: "BASIC", lang_key: "PM_sch_mode4" }
      };
    },
    tableData: function () {
      return this.$lodash.cloneDeep(Object.values(this.tableDataObj || {}));
    },
    tableDataObj: function () {
      console.log("this.$lodash.cloneDeep(Object.values(this.tableDataObj || {})", this.getPondTSTableData[this.selectedDay + "_" + this.pondId]);
      return this.getPondTSTableData[this.selectedDay + "_" + this.pondId];
    },
    selectedDay: function () {
      return this.getSelectedDay;
    },
    pondId: function () {
      return this.pond._id;
    },
    getSTId() {
      return this.getSelectedPondStIdsToPmIds;
    },
    dateRange: function () {
      return this.getDateRange;
    },
    dateRange0: function () {
      return this.dateRange[0];
    },
    dateRange1: function () {
      return this.dateRange[1];
    },
    sortedTableData: function () {
      return this.$lodash
        .cloneDeep(this.tableData)
        .sort((a, b) => a.s_time_secs - b.e_time_secs);
    }
  },
  mounted() {
    return this.validScheduleCheck();
  },
  methods: {
    ...mapActions("pondMotherSchedules", {
      updatePondTSTime: "updatePondTSTime",
      updatePondTSFeedConfigParams: "updatePondTSFeedConfigParams",
      addPondTSToPondTSTableData: "addPondTSToPondTSTableData",
      deletePondTSFromPondTSTable: "deletePondTSFromPondTSTable",
      savePondLevelSchedule: "savePondLevelSchedule"
    }),
    ...mapMutations("pondMotherSchedules", {
      CHECK_VALID_POND_DATA_TO_SAVE:
        "CHECK_VALID_SCHEDULE_MODE_POND_DATA_TO_SAVE"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    disableDelTS(pondTS) {
      const pmkeys = Object.keys(pondTS.pmsSubscribe);
      if (pmkeys.length < this.pmsLength) {
        return true;
      }
      if (pondTS.ui_status === "COMPLETED") {
        return true;
      }
      if (pondTS.ui_status === "RUNNING") {
        return true;
      }
      if (!pondTS.enabled.act_btn_del) {
        return true;
      }
      if (this.isReadOnly) {
        return true;
      }
      return false;
    },
    disableMode(pondTS) {
      const casesToDisable = [
        this.commonDisableCasesForAllFields(pondTS, "mode"),
        pondTS.ui_status === POND_TS_STATUS.RUNNING,
        pondTS.ui_status === POND_TS_STATUS.UI_RUNNING
      ];
      if (casesToDisable.includes(true)) {
        return true;
      } else {
        return false;
      }
    },
    disableFeeding(pmTS) {
      const casesToDisable = [
        this.commonDisableCasesForAllFields(pmTS, "feeding_level")
      ];
      if (casesToDisable.includes(true)) {
        return true;
      } else {
        if (pmTS.mode !== "SHRIMP_TALK" && pmTS.mode !== 'AUTOMATIC') {
          return true;
        } else {
          return false;
        }
      }
    },
    feedingValue(data) {
      if (data.feeding_level === -1) {
        return 0;
      } else {
        return data.feeding_level ? data.feeding_level : 0;
      }
    },
    disabledAddScheds() {
      const scheduleCount = this.getRemainingTSToPondId(
        this.pondId,
        this.selectedDay
      );
      if (scheduleCount >= 8) return true;
      if (this.isReadOnly) return true;
      return false;
    },
    commonDisableCasesForAllFields: function (ts, field_name) {
      const pmkeys = Object.keys(ts.pmsSubscribe);
      const casesToDisable = [
        !ts.enabled[field_name],
        pmkeys.length < this.pmsLength,
        [
          POND_TS_STATUS.INVALID,
          POND_TS_STATUS.COMPLETED,
          POND_TS_STATUS.UI_COMPLETED,
          POND_TS_STATUS.PM_DIFF_STATUS,
          POND_TS_STATUS.STOPPED
        ].indexOf(ts.ui_status) > -1,
        !this.doPondTSHasSelectedPms(ts),
        this.validScheduleCheckData[ts.bk_id] ? this.validScheduleCheckData[ts.bk_id] : false
        // this.cmCurrTimeSecs(this.getUserTimeZoneString) > ts.e_time_secs
      ];
      //  this.checkEndTimeLessThanCurrentTime(ts),
      return casesToDisable.includes(true);
    },
    validScheduleCheck: function() {
      this.$lodash.cloneDeep(Object.values(this.tableDataObj || {})).map(ts => {
        this.validScheduleCheckData[ts.bk_id] = this.isCurrentDay && this.cmCurrTimeSecs(this.getUserTimeZoneString) > ts.e_time_secs
      })
    },
    disableFromTime(pondTS) {
      const casesToDisable = [
        this.commonDisableCasesForAllFields(pondTS, "from_time"),
        pondTS.ui_status === POND_TS_STATUS.RUNNING,
        pondTS.ui_status === POND_TS_STATUS.UI_RUNNING
      ];
      // this.checkSTimeLessThanCurrentTime(pondTS),
      if (casesToDisable.includes(true)) {
        return true;
      } else {
        return false;
      }
    },
    disableToTime(pondTS) {
      const casesToDisable = [
        this.commonDisableCasesForAllFields(pondTS, "to_time")
      ];
      // this.checkSTimeLessThanCurrentTime(pondTS),
      if (casesToDisable.includes(true)) {
        return true;
      } else {
        return false;
      }
    },
    disableTotalFeed(pondTS) {
      const casesToDisable = [
        this.commonDisableCasesForAllFields(pondTS, "feed")
      ];
      if (casesToDisable.includes(true)) {
        return true;
      } else {
        return false;
      }
    },
    disableFeedGap(pondTS) {
      const casesToDisable = [
        this.commonDisableCasesForAllFields(pondTS, "feed_gap")
      ];
      if (casesToDisable.includes(true)) {
        return true;
      } else {
        return false;
      }
    },
    disableOCF(pondTS) {
      const casesToDisable = [
        this.commonDisableCasesForAllFields(pondTS, "ocf")
      ];
      if (casesToDisable.includes(true)) {
        return true;
      } else {
        if (!this.disableFeeding(pondTS)) {
          return true;
        } else {
          return false;
        }
      }
    },
    async handleAddTS(event) {
      try {
        this.$gblUAMCanUserEdit(this.permissions);
        this.canAddASchedule++;
        const presentPondTS = this.sortedTableData[this.tableData.length - 1];
        if (presentPondTS) {
          await this.addPondTSToPondTSTableData({
            pondId: this.pondId,
            selectedDay: this.selectedDay,
            pmsObj: this.getSelectedPmsObjWithDetails,
            siblingTSId: presentPondTS.ui_id,
            mode: this.mode !== "HYBRID" ? "FARMER" : 'HYBRID'
          });
        } else {
          await this.addPondTSToPondTSTableData({
            pondId: this.pondId,
            selectedDay: this.selectedDay,
            pmsObj: this.getSelectedPmsObjWithDetails,
            siblingTSId: undefined,
            mode: this.mode !== "HYBRID" ? "FARMER" : 'HYBRID'
          });
        }
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    handleDeleteTS(event, index, presentPondTS) {
      this.canAddASchedule--;
      if (presentPondTS.bk_id) {
        this.deletedTSBkIds.push(presentPondTS.bk_id);
      }
      this.deletePondTSFromPondTSTable({
        selectedDay: this.selectedDay,
        pondId: this.pondId,
        pondTSId: presentPondTS.ui_id
      });
    },
    handleChangeTPicker(updtPropVal, changedTSObj, propertyName) {
      try {
        const currTSId = changedTSObj.ui_id;
        this.updatePondTSTime({
          prop: propertyName,
          value: updtPropVal,
          selectedDay: this.selectedDay,
          currTSId,
          pondId: this.pondId,
          userTimeZoneString: this.getUserTimeZoneString
        });
      } catch (err) {
        console.error(err);
      }
    },
    async handleChangeInput(updatedValue, changedTSObj, propertyName) {
      const currTSId = changedTSObj.ui_id;
      await this.updatePondTSFeedConfigParams({
        prop: propertyName,
        value: updatedValue,
        selectedDay: this.selectedDay,
        currTSId,
        pondId: this.pondId,
        userTimeZoneString: this.getUserTimeZoneString
      });
    },
    async handleStatusChange(event, indexInTable, changedTSObj, newStatus) {
      try {
        this.$gblUAMCanUserEdit(this.permissions);
        await this.TSStatusChangeConfirm(newStatus);
        this.loading = true;
        await this.changeStatusOfPondTS({
          ui_id: this.tableData[indexInTable].ui_id,
          pondId: this.pondId,
          status: newStatus,
          mode: "FARMER"
        });
        this.ehm__unhandledErrorMessage = "";
        this.notifyScheduleSuccess("status");
        this.mixPanelEventGenerator({ eventName: "Feeding - PondMother - Pond Collapse - " + newStatus + " Schedules" });
      } catch (err) {
        if (err !== "cancel") {
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = false;
      }
    },
    async handleSaveSchedule() {
      try {
        this.$gblUAMCanUserEdit(this.permissions);
        this.CHECK_VALID_POND_DATA_TO_SAVE({
          selectedDay: this.selectedDay,
          pondId: this.pondId,
          deletedTSBkIds: this.deletedTSBkIds,
          getSTId: this.getSTId
        });
        await this.checkWhetherToSaveSameSchedForRemainingDays();
        this.loading = true;
        await this.savePondLevelSchedule();
        this.ehm__unhandledErrorMessage = "";
        this.notifyScheduleSuccess("saved");
        this.mixPanelEventGenerator({ eventName: "Feeding - PondMother - Pond Collapse - Save Schedules" });
      } catch (err) {
        this.errorFailToSaveHandler(err);
      } finally {
        this.loading = false;
      }
    },
    ehm__error422Handler: function (err) {
      const errors = err.response.data.errors;
      let message =
        err.response.data.message || this.$t("Something_went_wrong_try_again");
      if (
        errors &&
        Array.isArray(errors.details) &&
        errors.details.length > 0
      ) {
        message = errors.details[0].message;
      }
      this.ehm__errorFailToSave([{ message }]);
    }
  }
};
</script>

<style lang="scss">
.list-item__color-swatch_pm {
  height: 10px !important;
  width: 10px !important;
}
.option_span {
  margin-left: 4px;
}
.mode_dropdown_alignment {
  .el-input__inner {
     padding-left: 0px !important;
     padding-right: 16px !important;
     display: flex;
     justify-content: center;
  }
  .el-input__prefix {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    padding-left: 5px;
  }
}
</style>
