var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"justify":"center","element-loading-background":"white"}},[_c('el-row',[_c('el-row',{attrs:{"type":"flex","justify":"center"}},[(_vm.ehm__unhandledErrorMessage !== '')?_c('el-alert',{attrs:{"title":_vm.$t('Comn_alerts'),"type":"error","show-icon":""},on:{"close":_vm.ehm__handleAlertClose}},[_vm._v(_vm._s(_vm.ehm__unhandledErrorMessage))]):_vm._e()],1)],1),_vm._v(" "),_c('el-row',[_c('el-col',{attrs:{"span":16}},[_c('el-form',{staticClass:"mode-collapse-item"},[_c('el-table',{staticClass:"card-row-table",attrs:{"data":_vm.tableData,"size":"mini","indent":10,"border":false,"stripe":false,"header-row-class-name":"card-row-table__header-row","row-class-name":_vm.functionPondTSTableRowClassName}},[_c('template',{slot:"empty"},[_c('table-empty-state')],1),_vm._v(" "),_c('el-table-column',{attrs:{"width":_vm.tableColumns.total_feed_kgs.width,"header-align":"center","align":"center","label":_vm.tableColumns.total_feed_kgs.label},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{attrs:{"type":"number","size":"mini","min":0,"title":"","step":0.01,"precision":2,"disabled":_vm.disableTotalFeed(scope.row),"controls":false},on:{"change":function($event){return _vm.handleChangeInput(
                    $event,
                    scope.$index,
                    scope.row,
                    'total_feed_kgs'
                  )}},model:{value:(scope.row.feed),callback:function ($$v) {_vm.$set(scope.row, "feed", $$v)},expression:"scope.row.feed"}})]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"width":_vm.tableColumns.ocf_g.width,"header-align":"center","align":"center","label":_vm.tableColumns.ocf_g.label},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{attrs:{"type":"number","size":"mini","min":100,"step":50,"precision":0,"disabled":_vm.disableOCF(scope.row),"controls":false},on:{"change":function($event){return _vm.handleChangeInput($event, scope.$index, scope.row, 'ocf_g')}},model:{value:(scope.row.ocf),callback:function ($$v) {_vm.$set(scope.row, "ocf", $$v)},expression:"scope.row.ocf"}})]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"width":_vm.tableColumns.dispensed_feed.width,"header-align":"center","align":"center","label":_vm.tableColumns.dispensed_feed.label},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{attrs:{"type":"number","size":"mini","value":scope.row.dispensed_feed,"precision":0,"disabled":true,"controls":false}})]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"width":_vm.tableColumns.actions.width,"header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-row',{attrs:{"type":"flex","align":"middle"}},[(!_vm.hidePondStatusActionBtns(scope.row))?[(scope.row.ui_status === _vm.POND_TS_STATUS.RUNNING)?_c('er-button',{staticClass:"btn-actions",attrs:{"size":"mini","btnType":"pause","showIcon":true,"disabled":_vm.isReadOnly},on:{"click":function($event){return _vm.handleStatusChange(
                        $event,
                        scope.$index,
                        scope.row,
                        _vm.POND_TS_STATUS.PAUSED
                      )}}}):_vm._e(),_vm._v(" "),(scope.row.ui_status === _vm.POND_TS_STATUS.PAUSED)?_c('er-button',{staticClass:"btn-actions",attrs:{"size":"mini","btnType":"play","showIcon":true,"disabled":_vm.isReadOnly},on:{"click":function($event){return _vm.handleStatusChange(
                        $event,
                        scope.$index,
                        scope.row,
                        _vm.POND_TS_STATUS.RUNNING
                      )}}}):_vm._e(),_vm._v(" "),(
                      [_vm.POND_TS_STATUS.RUNNING, _vm.POND_TS_STATUS.PAUSED].indexOf(
                        scope.row.ui_status
                      ) > -1
                    )?_c('er-button',{staticClass:"btn-actions",attrs:{"size":"mini","btnType":"stop","showIcon":true,"disabled":_vm.isReadOnly},on:{"click":function($event){return _vm.handleStatusChange(
                        $event,
                        scope.$index,
                        scope.row,
                        _vm.POND_TS_STATUS.STOPPED
                      )}}}):_vm._e()]:_vm._e()],2)]}}])})],2),_vm._v(" "),(!_vm.hideAddSaveBtnsAtPondLevel)?_c('el-row',{staticClass:"save-btn-container",attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":6}},[_c('er-button',{attrs:{"size":"mini","btnType":"save","showLabel":true,"showIcon":true,"disabled":_vm.isReadOnly},on:{"click":_vm.handleSaveSchedule}})],1)],1):_vm._e()],1)],1),_vm._v(" "),_c('el-col',{staticClass:"add-btn-container",attrs:{"span":8}},[(!_vm.hideAddSaveBtnsAtPondLevel)?_c('el-row',{attrs:{"type":"flex","justify":"end"}},[_c('er-button',{attrs:{"size":"mini","btnType":"add","disabled":_vm.disabledAddScheds(),"icon":"el-icon-circle-plus-outline","showLabel":true},on:{"click":_vm.handleAddTS}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }