<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondAutomaticModeCollapseItem.vue
Description: This file contains UI components used to display collapsable pond of automatic mode in PondMother schedules page - Pond Level
-->
<template>
  <el-row v-loading="loading" element-loading-background="white">
    <el-row>
      <el-row type="flex" justify="center">
        <el-alert
          :title="$t('Comn_alerts')"
          type="error"
          @close="ehm__handleAlertClose"
          v-if="ehm__unhandledErrorMessage != ''"
          show-icon
          >{{ ehm__unhandledErrorMessage }}</el-alert
        >
      </el-row>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form
          class="mode-collapse-item"
          @submit.native.prevent="handleSaveSchedule"
        >
          <el-table
            :data="tableData"
            size="mini"
            :indent="10"
            :border="false"
            :stripe="false"
            class="card-row-table"
            header-row-class-name="card-row-table__header-row"
            :row-class-name="functionPondTSTableRowClassName"
          >
            <template slot="empty">
              <table-empty-state>
                <el-tag v-if="!doesStHasPmsAssigned">
                  {{
                    this.$t("item_not_yet_assigned_to_source", {
                      item: $t("PM_pondmothers"),
                      source: $t("Comn_shrimptalk"),
                    })
                  }}
                </el-tag>
                <el-tag v-else-if="getListOfNotYetStartedSts.length > 0">
                  <p>
                    {{
                      this.$t("items_will_start_at_time", {
                        item: $t("PM_pond_feeding"),
                        time: getStartTimeOfSt,
                      })
                    }}
                  </p>
                </el-tag>
                <el-tag v-else>
                  <p>{{ $t("pond_should_be_assigned_to_shrimptalk") }}</p>
                </el-tag>
              </table-empty-state>
            </template>
            <el-table-column
              :width="tableColumns.total_feed_kgs.width"
              header-align="center"
              align="center"
              :label="tableColumns.total_feed_kgs.label"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  size="mini"
                  v-model="scope.row.feed"
                  :min="0"
                  title
                  :step="0.01"
                  :disabled="disableTotalFeed(scope.row)"
                  :controls="false"
                  @change="
                    handleChangeInput($event, scope.row, 'total_feed_kgs')
                  "
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              :width="tableColumns.ocf_g.width"
              header-align="center"
              align="center"
              :label="tableColumns.ocf_g.label"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  size="mini"
                  :value="scope.row.ocf"
                  :min="200"
                  :precision="0"
                  title
                  :step="50"
                  :max="2500"
                  :disabled="true"
                  :controls="false"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              v-if="upm__showFeedGapField"
              :width="tableColumns.feed_gap.width"
              :label="tableColumns.feed_gap.label"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  size="mini"
                  title
                  v-model="scope.row.feed_gap"
                  :precision="2"
                  :min="0"
                  :controls="false"
                  :disabled="true"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              :width="tableColumns.dispensed_feed.width"
              header-align="center"
              align="center"
              :label="tableColumns.dispensed_feed.label"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  size="mini"
                  title
                  v-model="scope.row.dispensed_feed"
                  :precision="2"
                  :min="0"
                  :controls="false"
                  :disabled="true"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
          <el-row
            class="save-btn-container"
            type="flex"
            justify="start"
            v-if="!hideAddSaveBtnsAtPondLevel"
          >
            <er-button
              size="mini"
              btnType="save"
              :showLabel="true"
              :showIcon="true"
              :disabled="isReadOnly"
              @click="handleSaveSchedule"
            ></er-button>
          </el-row>
        </el-form>
      </el-col>
      <!-- <el-col :span="8" class="add-btn-container">
        <el-row type="flex" justify="end" v-if="!hideAddSaveBtnsAtPondLevel">
          <er-button
            size="mini"
            type="danger"
            :disabled="isReadOnly"
            @click="handlePondClearFutureSchedules($event, pondId)"
            >{{ $t("PM_clr_future_sch") }}
          </er-button>
        </el-row>
      </el-col> -->
    </el-row>
  </el-row>
</template>
<script>
import pmSchedulesMixin from "@/mixins/pmSchedulesMixin.js";
import { mapGetters, mapActions, mapMutations } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { POND_TS_STATUS } from "@/constants/schedule";

// const MAX_DAY_LIMIT = 86412345;
export default {
  mixins: [pmSchedulesMixin, errorHandlerMixin],
  props: ["pond"],
  data: function () {
    return {
      pastPickedTime: [],
      timePickerOptions: {
        format: "HH:mm"
      },
      ehm__errMessagesObject: {},
      loading: false
    };
  },

  computed: {
    ...mapGetters("pondMotherSchedules", {
      getSelectedDay: "getSelectedDay",
      getPondTSTableData: "getPondTSTableData",
      getDateRange: "getDateRange",
      getSelectedPondStIdsToPmIds: "getSelectedPondStIdsToPmIds",
      getRemainingTSToPondId: "getRemainingTSToPondId",
      getSelectedPondStIdToPmIds: "getSelectedPondStIdToPmIds",
      getStsYetToStartAtGivenTime: "getStsYetToStartAtGivenTime",
      getSelectedPondStIdToTimings: "getSelectedPondStIdToTimings"
    }),
    ocfValue: function () {
      return (pondTS) => {
        return pondTS.ui_status === POND_TS_STATUS.TO_BE_RUN ? 0 : pondTS.ocf_g;
      };
    },
    feedGapValue: function () {
      return (pondTS) => {
        return pondTS.ui_status === POND_TS_STATUS.TO_BE_RUN
          ? 0
          : pondTS.feed_gap_mins;
      };
    },
    tableData: function () {
      return this.$lodash.cloneDeep(Object.values(this.tableDataObj || {}));
    },
    tableDataObj: function () {
      return this.getPondTSTableData[this.selectedDay + "_" + this.pondId];
    },
    selectedDay: function () {
      return this.getSelectedDay;
    },
    pondId: function () {
      return this.pond._id;
    },
    getListOfNotYetStartedSts() {
      const currTimeSecs = this.dhm__dateUtilsLib.getCurrTimeSecsInGivenTZ(
        this.getUserTimeZoneString
      );
      const arrSts = this.getStsYetToStartAtGivenTime(currTimeSecs).sort(
        (st1, st2) =>
          this.getSelectedPondStIdToTimings[st1._id].s_time_in_seconds -
          this.getSelectedPondStIdToTimings[st2._id].s_time_in_seconds
      );
      console.log(arrSts);
      return arrSts;
    },
    doesStHasPmsAssigned() {
      return this.getListOfNotYetStartedSts.some((x) => x.pond_mothers.length);
    },
    getFirstNotYetStartedSt() {
      return this.getListOfNotYetStartedSts[0];
    },
    getStartTimeOfSt() {
      return this.getFirstNotYetStartedSt.settings.start_time.substr(0, 5);
    },
    groupNotStartedStsBySTime() {
      return this.getListOfNotYetStartedSts.reduce((accSts, st) => {
        if (accSts[st.s_time] === undefined) {
          accSts[st.s_time] = [];
        }
        accSts[st.s_time].push(st);
      }, {});
    },
    canAddASchedule: function () {
      return (scope) => {
        const tableDataLength = this.tableData.length;

        return (
          tableDataLength < 8 &&
          (tableDataLength === 1 || scope.$index === tableDataLength - 1)
        );
      };
    },
    dateRange: function () {
      return this.getDateRange;
    },
    dateRange0: function () {
      return this.dateRange[0];
    },
    dateRange1: function () {
      return this.dateRange[1];
    },
    hasStsAssigned: function () {
      const pmIdsToSts = this.getSelectedPondStIdsToPmIds;
      return Object.values(pmIdsToSts).every((stId) => stId);
    }
  },
  methods: {
    ...mapActions("pondMotherSchedules", {
      updatePondTSTime: "updatePondTSTime",
      updatePondTSFeedConfigParams: "updatePondTSFeedConfigParams",
      addPondTSToPondTSTableData: "addPondTSToPondTSTableData",
      deletePondTSFromPondTSTable: "deletePondTSFromPondTSTable",
      savePondLevelSchedule: "savePondLevelSchedule"
    }),
    ...mapMutations("pondMotherSchedules", {
      CHECK_VALID_POND_DATA_TO_SAVE:
        "CHECK_VALID_AUTOMATIC_MODE_POND_DATA_TO_SAVE"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    disabledAddScheds() {
      const scheduleCount = this.getRemainingTSToPondId(
        this.pondId,
        this.selectedDay
      );
      if (scheduleCount >= 1) return true;
      if (this.isReadOnly) return true;
      return false;
    },
    functionPondTSTableRowClassName({ row, rowIndex }) {
      const stPmIds = this.getSelectedPondStIdToPmIds[row.shrimp_talk_id] || [];
      const pmkeys = Object.keys(row.pmsSubscribe);
      const rowClass = [this.tsCssClassNames.default];
      if (row.status === POND_TS_STATUS.COMPLETED) {
        rowClass.push(this.tsCssClassNames.COMPLETED);
      } else if (pmkeys.length < stPmIds.length) {
        rowClass.push(this.tsCssClassNames.SCHED_MISMATCHED);
      }
      return rowClass.join(" ");
    },
    commonDisableCasesForAllFields: function (ts, field_name) {
      // const arrStIds = [...ts.setStIds];
      const selectedPmsIds = this.getSelectedPmIds;
      // const stTimings = this.getSelectedPondStIdToTimings[arrStIds[0]];
      const pmkeys = Object.keys(ts.pmsSubscribe);
      const casesToDisable = [
        !ts.enabled[field_name],
        pmkeys.length < selectedPmsIds.length,
        [
          POND_TS_STATUS.INVALID,
          POND_TS_STATUS.COMPLETED,
          POND_TS_STATUS.UI_COMPLETED,
          POND_TS_STATUS.PM_DIFF_STATUS,
          POND_TS_STATUS.STOPPED
        ].indexOf(ts.ui_status) > -1
      ];
      return casesToDisable.includes(true);
    },
    disableDelTS(pondTS) {
      const pmkeys = Object.keys(pondTS.pmsSubscribe);
      if (pmkeys.length < this.pmsLength) {
        return true;
      }
      // let conditions = [];
      return false;
    },
    disableTotalFeed(pondTS) {
      return this.commonDisableCasesForAllFields(pondTS, "feed");
    },
    disableFG(pm) {
      return false;
    },
    disableOCF(pm) {
      return false;
    },
    async handleAddTS(event, posInTable, presentPondTS) {
      try {
        this.$gblUAMCanUserEdit(this.permissions);
        await this.addPondTSToPondTSTableData({
          pondId: this.pondId,
          selectedDay: this.selectedDay,
          pmsObj: this.getSelectedPmsObjWithDetails,
          siblingTSId: presentPondTS.ui_id,
          mode: "SHRIMP_TALK"
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    handleDeleteTS(event, index, presentPondTS) {
      this.deletePondTSFromPondTSTable({
        selectedDay: this.selectedDay,
        pondId: this.pondId,
        pondTSId: presentPondTS.ui_id
      });
    },
    handleChangeTPicker(updtPropVal, changedTSObj, propertyName) {
      // need to assign object containing {next:TS,prev:TS,updatedValue:value} while updating the from time
      // const prevNextTIntvl = this.getPrevAndNextValue(posInTable);
      try {
        const currTSId = changedTSObj.ui_id;
        this.updatePondTSTime({
          prop: propertyName,
          value: updtPropVal,
          selectedDay: this.selectedDay,
          currTSId,
          pondId: this.pondId
        });
      } catch (err) {
        console.error(err);
      }
    },
    handleChangeInput(updatedValue, changedTSObj, propertyName) {
      // console.log(event, propertyName);
      const currTSId = changedTSObj.ui_id;
      this.updatePondTSFeedConfigParams({
        prop: propertyName,
        value: updatedValue,
        selectedDay: this.selectedDay,
        currTSId,
        pondId: this.pondId
      });
    },
    async handleStatusChange(event, indexInTable, changedTSObj, newStatus) {
      try {
        this.$gblUAMCanUserEdit(this.permissions);
        await this.TSStatusChangeConfirm(newStatus);
        this.loading = true;
        await this.changeStatusOfPondTS({
          ui_id: this.tableData[indexInTable].ui_id,
          pondId: this.pondId,
          status: newStatus,
          mode: "SHRIMP_TALK"
        });
        this.notifyScheduleSuccess("status");
        this.mixPanelEventGenerator({ eventName: "Feeding - PondMother - Pond Collapse - " + newStatus + " Schedules" });
      } catch (err) {
        if (err !== "cancel") {
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = false;
      }
      console.log(newStatus, this.tableData[indexInTable].bk_id, this.pondId);
    },
    async handleSaveSchedule() {
      try {
        this.$gblUAMCanUserEdit(this.permissions);
        if (!this.hasStsAssigned) {
          throw {
            type: "store",
            message: "pond_should_be_assigned_to_shrimptalk",
            message_type: "lang_key"
          };
        }
        this.CHECK_VALID_POND_DATA_TO_SAVE({
          selectedDay: this.selectedDay,
          pondId: this.pondId
        });
        await this.checkWhetherToSaveSameSchedForRemainingDays();
        this.loading = true;
        await this.savePondLevelSchedule();
        this.ehm__unhandledErrorMessage = "";
        this.notifyScheduleSuccess("saved");
        this.mixPanelEventGenerator({ eventName: "Feeding - PondMother - Pond Collapse - Save Schedules" });
      } catch (err) {
        this.errorFailToSaveHandler(err);
      } finally {
        this.loading = false;
      }
    },
    ehm__error422Handler: function (err) {
      const errors = err.response.data.errors;
      let message =
        err.response.data.message || this.$t("Something_went_wrong_try_again");
      if (
        errors &&
        Array.isArray(errors.details) &&
        errors.details.length > 0
      ) {
        message = errors.details[0].message;
      }
      this.ehm__errorFailToSave([{ message }]);
    }
  }
};
</script>

<style lang="scss"></style>
