<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: mapPondAlerts.vue
Description: This file contains UI components to show pond alerts in maps page
-->
<template>
  <er-data-tables
    ref="table"
    unique-id="pond-alerts-table__table"
    layout="table"
    :key="$i18n.locale"
    :tableData="alertsTableData"
    :sortMethodForColumns="sortMethodForColumns"
    :columns="tableColumns"
    :disable-check="false"
    :el-table-props="tableProps"
    :action-column="false"
    type="white-header-table"
  >
    <template slot="empty">
      <span>
        <img class="no-data__img" src="@/assets/no_data.svg" />
      </span>
      <span class="no-data__text">
        <h4>{{ $t("No_alerts") }}</h4>
      </span>
    </template>
    <template v-slot:device_type="{ row }">
      {{ $tc($constants.deviceTypes[row.data.device_type].lang_key, 1) }}
    </template>
    <template v-slot:device_id="{ row }">
      <span :title="row.data.device_code">
        <template>
          {{ upm__getValue(row.data) }}
        </template>
      </span>
    </template>
    <template v-slot:status="{ row }">
      <el-tooltip
        :content="renderAlertName(row.data.error_code)"
        placement="bottom"
      >
        <img
          rel="prerender"
          class="alert-icon"
          :src="renderAlertIcon(row.data.error_code)"
        />
      </el-tooltip>
    </template>
    <template v-slot:time="{ row }">
      <span>{{ formatDate(row.data) }}</span>
    </template>
  </er-data-tables>
</template>

<script>
import { mapGetters } from "vuex";
import errorAlertsUtils from "@/utils/errorAlertsUtils";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
export default {
  mixins: [datesHandlerMixin, userPreferenceMixin],
  data: function () {
    return {
      tableProps: {
        border: false,
        stripe: false,
        size: "small",
        defaultSort: {
          prop: "pond_name",
          order: "ascending"
        }
      }
    };
  },
  computed: {
    ...mapGetters("googleMaps", {
      getPondIdToErrorAlertsObj: "getPondIdToErrorAlertsObj",
      getSelectedPond: "getSelectedPond"
    }),
    ...mapGetters("user", {
      getPreferredUnits: "getPreferredUnits"
    }),
    sortMethodForColumns: function () {
      return {
        user_priority: (a, b) => this.$commonUtils.alphaNumericComparator(a, b)
      };
    },
    tableColumns() {
      return {
        device_type: {
          label: this.$t("Comn_device_type"),
          prop: "device_type",
          sortable: true
        },
        device_id: {
          label: this.$t("Comn_device_id"),
          prop: "pond_mother_code",
          sortable: true
        },
        status: {
          label: this.$t("Comn_alert"),
          prop: "user_priority",
          sortable: true
        },
        time: {
          label: this.$t("Comn_time"),
          prop: "updated_at",
          sortable: true
        }
      };
    },
    getPondId() {
      return this.getSelectedPond._id;
    },
    alertsTableData() {
      console.log(this.getPondIdToErrorAlertsObj[this.getPondId]);
      return this.getPondIdToErrorAlertsObj[this.getPondId] || [];
    }
  },
  methods: {
    renderAlertIcon(alertCode) {
      return errorAlertsUtils.renderDeviceAlertIcon(alertCode);
      // console.log(error);
    },
    renderAlertName(data) {
      return this.$t(errorAlertsUtils.renderAlertName(data));
    },
    formatDate: function (pond) {
      if (pond.error_code === "NO_SCHEDULES") return "--";
      return this.dhm__dateUtilsLib.getFormatByTimeZoneOnUTC(
        pond.date,
        this.upm__getFormatDateAndTimeString,
        this.getUserTimeZoneString,
        { locale: this.$i18n.locale }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
#pond-alerts-table__table {
  margin-left: 5px;
  .alert-icon {
    @include icon-size(svg);
    vertical-align: -4px;
    @include responsiveProperty(vertical-align, -4px, -4px, -6px);
  }
  .no-data__text {
    @include responsiveProperty(
      font-size,
      $app_font_size_v_medium,
      $app_font_size_small,
      $app_font_size_1
    );
  }
}
</style>
<style lang="scss">
#pond-alerts-table__table {
  .el-table .cell {
    color: black;
  }
}
</style>
