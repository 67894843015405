<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: searchPlace.vue
Description: This file contains UI compenents that is shown as place search bar in maps page
-->
<template>
  <el-row class="search-place-map">
    <el-select
      v-model="inputPlaceSearch"
      filterable
      remote
      size="small"
      value-key="description"
      reserve-keyword
      :placeholder="$t('Comn_search')"
      :remote-method="getPlacePredictions"
      :loading="loading"
      @change="handleSelectedPlace"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.description"
        :value="item"
      ></el-option>
    </el-select>
  </el-row>
</template>
<script>
export default {
  props: ["map"],
  data: function () {
    return {
      searchedPlace: "adsas",
      timeout: 400,
      marker: null,
      inputPlaceSearch: {},
      options: [],
      loading: false,
      autoCompleteRequest: {}
    };
  },
  beforeDestroy () {
    if (this.map != null && this.marker != null) {
      this.map.setMap(this.marker, false);
    }
  },
  methods: {
    handleSelectedPlace (selectedPlace) {
      console.log(selectedPlace);
      const predictionService = new google.maps.places.PlacesService(
        this.map.map
      );
      const placeRequest = {
        fields: ["geometry.location"],
        placeId: selectedPlace.place_id,
        sessionToken: new google.maps.places.AutocompleteSessionToken()
      };
      let location;
      predictionService.getDetails(
        placeRequest,
        (placeRequest, placeServiceStatus) => {
          if (placeServiceStatus === "OK") {
            location = placeRequest.geometry.location;
            this.map.panTo(location);
            this.placeMarker({ ...selectedPlace, location });
          } else {
            this.$notify({
              title: this.$t("failed"),
              message: this.$t("Something_went_wrong"),
              type: "error",
              duration: 4500
            });
          }
        }
      );
    },
    initAutoComplete (inputText) {
      this.autoCompleteRequest = {
        input: inputText,
        sessionToken: new google.maps.places.AutocompleteSessionToken(),
        types: ["geocode"]
      };

      // else {
      //   this.loading = false;
      //   this.autoCompleteRequest = {
      //     bounds: this.map.map.getBounds(),
      //     input: String.fromCharCode(
      //       65 + Math.floor(Math.random() * Math.floor(26))
      //     ),
      //     // offset:0,
      //     sessionToken: new google.maps.places.AutocompleteSessionToken(),
      //     types: ["geocode"],
    },
    getPlacePredictions (inputText) {
      if (this.map != null && inputText !== "") {
        this.loading = true;
        this.initAutoComplete(inputText);
        const autoCompleteService = new google.maps.places.AutocompleteService();
        autoCompleteService.getPlacePredictions(
          this.autoCompleteRequest,
          async (arrPrediction, placeServiceStatus) => {
            if (placeServiceStatus === "OK") {
              this.options = arrPrediction;
            }
            this.loading = false;
          }
        );
      } else {
        this.options = [];
        this.loading = false;
      }
    },
    placeMarker (placeSelected) {
      this.input = placeSelected.description;
      this.map.setMapCenter(placeSelected.location);
      if (this.marker != null) {
        this.map.setMap(this.marker, false);
      }
      this.marker = this.map.addMarkerToMap(placeSelected.location);
      this.map.setMap(this.marker, true);
    }
  }
};
</script>
<style lang="scss" scoped>
.search-place-map {
  position: absolute;
  top: 10px;
  left: 25.5rem;
  .el-input__inner:focus {
    cursor: text;
  }
  .el-select {
    width: 31.2rem;
  }
}
</style>
