<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: mapControls.vue
Description: This file contains UI components to show  map controls in maps page
-->
<template>
  <div style="display: none">
    <div>
      <!--<el-autocomplete
        id="search-place-input"
        v-model="input"
        :fetch-suggestions="querySearch"
        :hide-loading="false"
        :trigger-on-focus="false"
        placeholder="Search Places"
        @select="handleSelect"
      >
        :debounce="400"
        <template slot-scope="{ item }">
          <span class="link">{{ item.description }}</span>
        </template>
      </el-autocomplete>-->
      <el-row type="flex" id="infowindow-content">
        <el-row>
          <el-col>
            <span id="place-icon" class="title"></span>
          </el-col>
          <el-col>
            <span id="place-name" class="title"></span>
          </el-col>
          <el-col>
            <span id="place-address"></span>
          </el-col>
        </el-row>
        <br />
        <el-card id="info-pond-health-hint">
          <el-row type="flex">
            <el-row type="flex" class="inactive-pond">
              <div
                class="color-box"
                :style="{ 'background-color': '#dcdcdc' }"
              />
              <span>{{ $t("inactive_pond") }}</span>
            </el-row>
            <el-divider direction="vertical"></el-divider>
            <el-row type="flex" class="low-do">
              <div
                class="color-box"
                :style="{ 'background-color': '#ffa500' }"
              />

              <span>{{ $t("low_do") }}</span>
            </el-row>
            <el-divider direction="vertical"></el-divider>
            <el-row type="flex" class="low-do">
              <div class="color-box" :style="{ 'background-color': '#f00' }" />

              <span>{{ $t("Comn_critical_do") }}</span>
            </el-row>
            <el-divider direction="vertical"></el-divider>

            <el-row type="flex" class="active-pond">
              <div
                class="color-box"
                :style="{ 'background-color': '#0073b5' }"
              />

              <span>{{ $t("active_pond") }}</span>
            </el-row>
          </el-row>
        </el-card>
        <br />
        <el-row>
          <er-button type="text" id="close" icon="el-icon-close"></er-button>
        </el-row>
      </el-row>
      <!-- <el-input
        :placeholder="$t('Map_enter_location')"
        id="search-place-input"
        v-model="searchedPlace"
        @change="handleSelectedPlace"
      ></el-input>-->
    </div>
  </div>
</template>

<script>
export default {
  props: ["map"],
  data: function () {
    return {
      timeout: 400,
      infoWindowContent: ""
    };
  },
  computed: {},
  mounted () {},
  methods: {
    handleSelect (placeSelected) {
      this.input = placeSelected.description;
      this.map.setMapCenter(placeSelected.location);
      if (this.marker != null) {
        this.map.setMap(this.marker, false);
      }
      this.marker = this.map.addMarkerToMap(placeSelected.location);
      this.map.setMap(this.marker, true);
    }
  }
};
</script>

<style lang="scss">
#search-place-input {
  margin-top: 10px;
  width: 500px;
}
#infowindow-content {
  .el-card {
    height: 100px;
  }
}
#info-pond-health-hint {
  margin-bottom: 5px;
  z-index: 9999990 !important;
  left: 5px !important;
  .el-card__body {
    padding: 10px !important;
    display: inline-flex;
    font-size: 14px;
    .el-row {
      cursor: pointer;
      & > .el-row {
        margin-right: 3px;
      }
      .el-row:after {
        content: "|";
      }
    }
    span {
      cursor: initial;
    }
    .color-box {
      @include responsiveProperty(width, 12px, 12px, 12px);
      @include responsiveProperty(height, 12px, 12px, 12px);
      border-radius: 2px;
    }

    .inactive-pond,
    .low-do,
    .active-pond {
      display: flex;
      align-items: center;
      span {
        margin-left: 4px;
      }
    }
    // span:before {
    //   display: inline-block;
    //   content: "";
    //   vertical-align: baseline;
    //   border: 5px #eea236 solid;
    //   margin-right: 2px;
    // }
    // .inactive-pond span:before {
    //   border-color: #dcdcdc;
    // }
    // .low-do span:before {
    //   border-color: #ff5f00;
    // }
    // .active-pond span:before {
    //   border-color: #0073b5;
    // }
  }
}
</style>
