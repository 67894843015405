<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: refresh.vue
Description: This file contains UI component which shows UI of refech icon used in feeding PondMother tab
-->
<template>
  <span>
    <el-tooltip :content="$t('refresh')" placement="top">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 6.99997V0L13.6485 2.34998C12.2026 0.899998 10.2064 0 7.99498 0C3.57223 0 0 3.57998 0 8C0 12.42 3.57223 16 7.99498 16C11.7223 16 14.8443 13.45 15.7298 10H13.6485C12.823 12.33 10.6116 14 7.99503 14C4.67796 14 1.99128 11.315 1.99128 8C1.99128 4.68501 4.67796 2.00001 7.99503 2.00001C9.65108 2.00001 11.137 2.69001 12.2227 3.77502L8.99564 7.00002H16V6.99997Z"
          fill="#979797"
        />
      </svg>
    </el-tooltip>
  </span>
</template>

<script>
export default {
  props: {}
};
</script>

<style lang="scss" scoped></style>
