<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondCollapse.vue
Description: This file contains UI components used to display collapsable ponds in PondMother Schedules page - Pond Level
-->
<template>
  <el-row class="pond-schedule-collapse">
    <el-collapse accordion :value="isValidToShow" @change="handleChange">
      <el-collapse-item
        :name="getSelectedPondId"
        v-loading="loading === getSelectedPondId"
        :disabled="disablePondCollapseItem"
      >
        <template slot="title">
          <el-row
            type="flex"
            justify="space-between"
            align="middle"
            class="collapse-header__row"
            :key="$i18n.locale"
          >
            <el-col :span="4" class="collapse-header__row-item">
              <p class="row-item__value truncate">
                {{ getSelectedPond.title }}
              </p>
            </el-col>
            <el-col v-if="isCurrentDay" :span="5" class="collapse-header__row-item"></el-col>
            <el-col :span="isCurrentDay ? 4 : 6" class="collapse-header__row-item">
              <p class="row-item__label truncate">{{ $t("PM_tf") }}</p>
              :
              <p
                class="row-item__value truncate"
                v-upm__units.digitPrecision.formatEmpty="unitsParamsForFeed"
              ></p>
              &nbsp;(
              <p
                class="row-item__value truncate"
                v-upm__units.digitPrecision="unitsParamsForFeedPerArea"
              ></p>
              )
            </el-col>
            <el-col :span="isCurrentDay ? 4 : 7" class="collapse-header__row-item">
              <p class="row-item__label truncate">
                {{ $t("Comn_df") }}
              </p>
              :
              <p
                class="row-item__value truncate"
                v-upm__units.digitPrecision.formatEmpty="
                  unitsParamsForDispensedFeed
                "
              ></p>
            </el-col>
            <el-col
              :span="3"
              class="collapse-header__row-item status-item-container"
              v-show="getDateQueryType === 'TODAY'"
            >
              <el-tooltip
                :content="getCommunicationStatusColor.translation"
                placement="top"
                effect="light"
                popperClass="pond-schedule-collapse-el-tooltip__popper"
              >
                <i
                  class="material-icons-round"
                  :style="`color:${getCommunicationStatusColor.color}`"
                  >signal_cellular_alt</i
                >
              </el-tooltip>

              <el-tooltip
                popperClass="pond-schedule-collapse-el-tooltip__popper"
                :content="getSchedStatus.translation"
                placement="top"
                effect="light"
              >
                <i
                  class="material-icons-round"
                  :style="`color:${getSchedStatus.color};`"
                  >sync</i
                >
              </el-tooltip>

              <el-tooltip
                popperClass="pond-schedule-collapse-el-tooltip__popper"
                :content="$t('refresh')"
                placement="top"
                effect="light"
              >
                <er-button
                  class="er-btn-refresh"
                  type="text"
                  :v-loading="loading === getSelectedPondId"
                  @click.native="handleRefresh($event, getSelectedPondId)"
                >
                  <i class="material-icons-round">refresh</i>
                </er-button>
              </el-tooltip>
            </el-col>
            <el-col :span="4" class="collapse-header__row-item">
              <p class="row-item__value truncate">
                {{ getPondFeedingLevelMode }}
              </p>
            </el-col>
          </el-row>
        </template>
        <template v-if="isValidToShow">
          <layout-toolbar justify="start">
            <el-row
              type="flex"
              justify="start"
              v-if="pondMode.mode === POND_MODES.MIXED"
              class="err-msg-row"
            >
              <el-tag effect="light" size="small">
                <i class="el-icon-info"></i>&nbsp;
                <span>{{ $t("PM_mode_missmatch_msg") }}</span>
              </el-tag>
            </el-row>
            <el-row
              type="flex"
              justify="start"
              v-else-if="!hideScheduleMisMatchMessage(getSelectedPondId)"
              class="err-msg-row"
            >
              <el-tag effect="light" size="small">
                <i class="el-icon-info"></i>&nbsp;
                <span>{{
                  getModeMismatchMessage[TS_PM_MODES[pondMode.mode]]
                }}</span>
              </el-tag>
            </el-row>
            <div class="filler"></div>
          </layout-toolbar>
          <component
            :is="pondModeToComponent[TS_PM_MODES[pondMode.mode]]"
            :mode="pondMode.mode"
            :pond="getSelectedPond"
          ></component>
        </template>
      </el-collapse-item>
    </el-collapse>
    <el-row tag="p" class="pm-collapse-header-title normal-text"
      >{{ $t("PM_pondmothers") }} {{ $t("PM_schedule") }}</el-row
    >
    <el-row>
      <pmCollapse :pond="getSelectedPond"></pmCollapse>
    </el-row>
  </el-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import pmCollapse from "./pmCollapse";
import pondScheduleModeCollapseItem from "./pondScheduleModeCollapseItem";
import pondBasicModeCollapseItem from "./pondBasicModeCollapseItem";
import pondAutomaticModeCollapseItem from "./pondAutomaticModeCollapseItem";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import pmSchedulesMixin from "@/mixins/pmSchedulesMixin";
import { DATE_OBJ_DATE_FORMAT_WITH_TIME } from "@/utils/commonUtils";
import { POND_MODES, TS_PM_MODES } from "@/constants/schedule";
import { generateColorForMode } from "@/utils/componentColors";
import Pond from "@/model/pond";

export default {
  mixins: [errorHandlerMixin, pmSchedulesMixin],
  components: {
    pmCollapse,
    pondScheduleModeCollapseItem,
    pondAutomaticModeCollapseItem,
    pondBasicModeCollapseItem
  },
  data: function() {
    return {
      collapseOpenPonds: [],
      setOfCollapseOpenPonds: new Set(),
      expandedPondKeys: [],
      currentActivePond: "",
      loading: false,
      modeArray: [],
      POND_MODES,
      TS_PM_MODES,
      pondModeToComponent: {
        [POND_MODES.SCHEDULE]: "pondScheduleModeCollapseItem",
        [POND_MODES.BASIC]: "pondBasicModeCollapseItem",
        [POND_MODES.AUTOMATIC]: "pondAutomaticModeCollapseItem",
        [POND_MODES.HYBRID]: "pondScheduleModeCollapseItem"
      }
    };
  },
  computed: {
    ...mapGetters("pondMotherSchedules", {
      getPondDetailsToPondId: "getPondDetailsToPondId",
      getPondCollapseTableData: "getPondCollapseTableData",
      getSelectedDay: "getSelectedDay",
      getPondTSTableData: "getPondTSTableData",
      getArrOfDaysFromDateRange: "getArrOfDaysFromDateRange",
      getIsPondEditableStatus: "getIsPondEditableStatus",
      getPondSchedLastUpdtStatus: "getPondSchedLastUpdtStatus",
      isCurrentDay: "isCurrentDay",
      isPastDay: "isPastDay",
      getDateQueryType: "getDateQueryType",
      getSelectedPond: "getSelectedPond",
      getCommunicationStatusForSelectedPond:
        "getCommunicationStatusForSelectedPond",
      isFutureDay: "isFutureDay",
      getStsYetToStartAtGivenTime: "getStsYetToStartAtGivenTime",
      getPondToPmsMapInSchedOnSelectedPondAndDate:
        "getPondToPmsMapInSchedOnSelectedPondAndDate",
      getSelectedPmIds: "getSelectedPmIds",
      getPondModeBySelectedPmIds: "getPondModeBySelectedPmIds",
      canEditAutomaticModePondTSForSelectedPond:
        "canEditAutomaticModePondTSForSelectedPond",
      getPmCollapseTableData: "getPmCollapseTableData",
      getSelectedPondPmsDetails: "getSelectedPondPmsDetails"
    }),
    ...mapGetters("user", {
      getUserCountryName: "getUserCountryName"
    }),
    disablePondCollapseItem() {
      return (
        this.isFutureDay &&
        ["SHRIMP_TALK", "MANUAL"].includes(TS_PM_MODES[this.pondMode.mode])
      );
    },
    getPondFeedingLevelMode() {
      const translations = {
        0: this.$t("PM_normal"),
        1: this.$t("PM_economical"),
        2: this.$t("PM_Aggressive"),
        3: this.$t("Comn_super_aggressive"),
        4: this.$t("PM_hybrid"),
        5: ""
      };
      const modeArray = []; // Clear the modeArray for each calculation

      for (const pmId in this.getPondToPmsMapInSchedOnSelectedPondAndDate) {
        const pmData = this.getPondToPmsMapInSchedOnSelectedPondAndDate[pmId];

        if (pmData.managed_by === "SHRIMP_TALK" && pmData.feeding_level >= 0) {
          modeArray.push(pmData.feeding_level);
        }
      }

      const uniqueModes = [...new Set(modeArray)]; // Ensure unique modes
      console.log("uniqueModes", uniqueModes);
      if (uniqueModes.length === 1) {
        return translations[uniqueModes[0]];
      } else if (uniqueModes.length > 1) {
        return translations[4]; // Hybrid
      } else {
        return translations[5]; // None
      }
    },
    getModeMismatchMessage() {
      return {
        [POND_MODES.HYBRID]: this.$t("PM_sch_mis_match_msg"),
        [POND_MODES.SCHEDULE]: this.$t("PM_sch_mis_match_msg"),
        [POND_MODES.BASIC]: this.$t("PM_sch_mis_match_msg"),
        [POND_MODES.AUTOMATIC]: this.getModeMismatchMessageForPondInAutomatic
      };
    },
    groupPmsByMode() {
      return Object.values(
        this.getPondToPmsMapInSchedOnSelectedPondAndDate
      ).reduce((acc, curr) => {
        if (!acc[curr.managed_by]) {
          acc[curr.managed_by] = [];
        }
        acc[curr.managed_by].push(curr);
        return acc;
      }, {});
    },
    getModeMismatchMessageForPondInAutomatic() {
      const {
        SCHEDULE_MISMATCH
        // ST_NOT_YET_STARTED,
      } = this.canEditAutomaticModePondTSForSelectedPond;
      const message = [];
      if (SCHEDULE_MISMATCH) {
        message.push(this.$t("PM_sch_mis_match_msg"));
      }
      return message.join(",");
    },
    pondTSTableData: function() {
      return this.$lodash.cloneDeep(
        Object.values(this.pondTStableDataObj || {})
      );
    },
    pondTStableDataObj: function() {
      return this.getPondTSTableData[
        this.selectedDay + "_" + this.getSelectedPondId
      ];
    },
    getStNames() {
      const currTimeSecs = this.dhm__dateUtilsLib.getCurrTimeSecsInGivenTZ(
        this.getUserTimeZoneString
      );
      return this.getStsYetToStartAtGivenTime(currTimeSecs)
        .map((x) => x.title || x.code)
        .join(",");
    },
    schedules() {
      return this.getPondCollapseTableData;
    },
    getSelectedPondCollapseHeaderData() {
      return this.schedules[0] || {};
    },
    hideScheduleMisMatchMessage() {
      return (pondId) => {
        if (TS_PM_MODES[this.pondMode.mode] === POND_MODES.AUTOMATIC) {
          const {
            SCHEDULE_MISMATCH
            // ST_NOT_YET_STARTED,
          } = this.canEditAutomaticModePondTSForSelectedPond;
          // ST_NOT_YET_STARTED ||
          return (
            this.isPastDay ||
            !SCHEDULE_MISMATCH ||
            this.pondTSTableData.length === 0
          );
        } else {
          const conditions = [
            this.isPastDay,
            this.getSelectedPmIds.length === 0,
            this.getIsPondEditableStatus(pondId)
          ];
          return conditions.some((x) => x);
        }
      };
    },
    getCommunicationStatusColor() {
      const { ON_LINE, OFF_LINE } = this.getCommunicationStatusForSelectedPond;
      if (ON_LINE.PM_COUNT > 0 && OFF_LINE.PM_COUNT > 0) {
        return {
          translation: this.$t("partially_online"),
          color: "#F08029"
        };
      } else if (OFF_LINE.PM_COUNT > 0) {
        return { translation: this.$t("offline"), color: "#E31A1C" };
      } else {
        return { translation: this.$t("online"), color: "#33A02C" };
      }
    },
    getSelectedPondId() {
      return this.getSelectedPond._id;
    },
    pondMode() {
      const translations = {
        SHRIMP_TALK: this.$t("PM_automatic"),
        HYBRID: this.$t("hybrid"),
        FARMER: this.$t("Comn_schedule"),
        MANUAL: this.$t("PM_sch_mode4")
      };
      const mode = this.getPondModeBySelectedPmIds;
      const color = (Pond.generateColorForMode(mode) || { color: "#000000" })
        .color;
      return { mode, color, translation: translations[mode] };
    },
    isValidToShow() {
      const canShowCollapse = [
        (this.getSelectedPondPmIds || []).length > 1,
        !this.disablePondCollapseItem
      ].every((x) => x);
      return canShowCollapse ? this.getSelectedPondId : "";
    },
    unitsParamsForFeedPerArea() {
      const pond = this.getSelectedPond || {};
      const feed = this.getSelectedPondCollapseHeaderData.feed || 0;
      return {
        value: [feed, pond.size || 0],
        units: [
          this.getPreferredUnits.feed_units.toLowerCase(),
          this.getPreferredUnits.pond_area_units.toLowerCase()
        ],
        previousunits: [
          this.upm__backendUnits.feed_units,
          this.upm__backendUnits.pond_area_units
        ],
        operation: ([param1, param2]) => {
          if (!param2) return 0;
          return param1 / param2;
        },
        displayFormatter: ([param1, param2]) => {
          return `${param1}/${param2}`;
        },
        modifiersParams: { digitPrecision: [2] }
      };
    },
    unitsParamsForFeed() {
      const feed = this.getSelectedPondCollapseHeaderData.feed || 0;
      const feedUnits = {
        value: feed,
        previousunits: this.upm__backendUnits.feed_units,
        units: this.getPreferredUnits.feed_units,
        modifiersParams: {
          digitPrecision: [2],
          formatEmpty: ["-"]
        }
      };
      return feedUnits;
    },
    unitsParamsForDispensedFeed() {
      const feed = this.getSelectedPondCollapseHeaderData.dispensed_feed || 0;
      const feedUnits = {
        value: feed,
        previousunits: this.upm__backendUnits.feed_units,
        units: this.getPreferredUnits.feed_units,
        modifiersParams: {
          digitPrecision: [2],
          formatEmpty: ["-"]
        }
      };
      return feedUnits;
    },
    selectedDay: function() {
      return this.getSelectedDay;
    },
    getSchedStatus: function() {
      const {
        SCHEDULES_NOT_UPDATED,
        SCHEDULES_UPDATED,
        updated_iso_string
      } = this.getPondSchedLastUpdtStatus(
        this.getSelectedDay,
        this.getSelectedPondId
      );
      const formatTime = this.dhm__dateUtilsLib.formatTZWithLocale(
        this.dhm__dateUtilsLib.parseISO(updated_iso_string),
        DATE_OBJ_DATE_FORMAT_WITH_TIME,
        this.getUserTimeZoneString,
        this.$i18n.locale
      );

      if (SCHEDULES_NOT_UPDATED > 0 && SCHEDULES_UPDATED > 0) {
        return {
          translation: this.$t("PM_sch_partial_updated"),
          color: "#F08029"
        };
      }
      if (SCHEDULES_NOT_UPDATED > 0) {
        return { translation: this.$t("PM_sch_not_updated"), color: "#E31A1C" };
      }
      if (SCHEDULES_UPDATED > 0) {
        return {
          translation: this.$t("schedule_updtd_in_device", {
            time: formatTime
          }),
          color: "#33A02C"
        };
      }
      return {
        translation: this.$t("PM_no_sch_today"),
        color: "#979797"
      };
    }
  },
  beforeRouteLeave(to, from, next) {
    this.modeArray = [];
  },
  mounted() {
    this.upm__setDataKeys("code", "title");
  },
  methods: {
    ...mapActions("pondMotherSchedules", {
      changeDateRange: "changeDateRange",
      clearPondTSTableData: "clearPondTSTableData",
      changePmsSelection: "changePmsSelection"
    }),
    generateColorForMode,
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handleChange() {
      this.mixPanelEventGenerator({
        eventName: "Feeding - PondMother - Pond Collapse"
      });
    },
    handlePmSelect(selectedPmIds) {
      this.changePmsSelection(selectedPmIds);
    },
    async handleRefresh(event, pondId) {
      event.stopPropagation();
      const initStartDate = this.dhm__dateUtilsLib.formatDate(
        this.dhm__dateUtilsLib.utcToZonedTime(
          new Date(),
          this.getUserTimeZoneString
        ),
        "yyyy-MM-dd"
      );
      const initEndDate = this.dhm__dateUtilsLib.formatDate(
        this.dhm__dateUtilsLib.utcToZonedTime(
          new Date(),
          this.getUserTimeZoneString
        ),
        "yyyy-MM-dd"
      );
      try {
        this.loading = pondId;
        await this.clearPondTSTableData({
          pondId,
          days: [this.selectedDay]
        });
        await this.changeDateRange([initStartDate, initEndDate]);
        this.mixPanelEventGenerator({
          eventName: "Feeding - PondMother - Pond Collapse - Reload"
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = "";
      }
    }
  }
};
</script>

<style lang="scss">
.pond-schedule-collapse {
  border: none;
  // varibles
  $text-color-with-dark-bg: #0a2463;
  // styles
  background-color: transparent;
  cursor: pointer;
  margin-left: 10px;
  .err-msg-row {
    margin-left: 10px;
    .el-tag {
      @include responsiveProperty(height, 25px, 28px, 31px);
      @include responsiveProperty(line-height, 22px, 25px, 28px);
      @include responsiveProperty(
        font-size,
        $app_font_size_v_medium,
        $app_font_size_small,
        $app_font_size_1
      );
    }
  }
  .cell .el-input {
    width: 100%;
  }
  .el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    content: unset;
  }
  .el-collapse {
    border: none;
  }
  .el-collapse-item {
    background-color: #ffffff;
    color: $text-color-with-dark-bg;
    margin-bottom: 5px;
    border-radius: 5px;
    .toolbar-layout + div {
      margin-top: 10px;
    }
    .el-collapse-item__header {
      @include responsiveProperty(
        font-size,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
      font-weight: 500;
      transition: none;
      @include responsiveProperty(height, 30px, 35px, 40px);
      @include responsiveProperty(line-height, 30px, 35px, 40px);
      background-color: transparent;
      color: $text-color-with-dark-bg;
      border-bottom: unset;
      &.is-active {
        border-bottom: 1px solid #82828217;
      }
      padding: 0px 10px;
      .collapse-header__row {
        width: 100%;
        .collapse-header__row-item {
          display: flex;
          .row-item__label {
            color: #828282;
          }
          .row-item__value {
            font-weight: 600;
            margin: auto 0px auto 6px;
            &:first-child {
              padding-left: 25px;
            }
          }
          .er-btn-refresh {
            padding: unset;
            .material-icons-round {
              color: #979797;
            }
          }
          &.status-item-container {
            align-items: center;
            justify-content: center;
            line-height: 1;
            & > :nth-child(2) {
              margin-right: 10px;
              margin-left: 10px;
            }
          }
        }
        .el-button--mini.is-plain.is-circle {
          padding: 3px;
        }
      }
      .el-icon-arrow-right {
        color: $text-color-with-dark-bg;
        font-weight: bolder;
        font-size: 16px;
        &:before {
          content: "\e6df";
        }
      }
      .el-collapse-item__arrow.el-icon-arrow-right {
        &.is-active {
          -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
        }
      }
    }
  }
  .el-collapse-item__content {
    padding: 10px;
    .col-clear-schedules {
      line-height: 3.8;
      text-align: right;
      .er-button {
        background-color: red;
        border: red;
        border-radius: 5px;
        color: white;
        font-weight: bold;
        &.is-disabled {
          opacity: 0.5;
        }
      }
    }
    .save-btn-container {
      padding-top: 15px;
    }
  }
  .pm-collapse-header-title {
    margin-bottom: 10px;
    padding-left: 10px;
  }
  .list-item__color-swatch {
    @include responsiveProperty(height, 10px, 12px, 14px, true);
    @include responsiveProperty(width, 10px, 12px, 14px, true);
    align-self: center;
    margin-right: 10px;
    @include icon-size(svg);
  }
}

.pond-schedule-collapse-el-tooltip__popper {
  border: 1px solid #606266 !important;
  color: #606266 !important;
}
.pond-schedule-collapse-el-tooltip__popper[x-placement^="top"] .popper__arrow {
  border-top-color: #606266 !important;
  bottom: -7px;
}
</style>
