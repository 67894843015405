<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: feedTable.vue
Description:This file is the child component of feeding.vue.This field shows the info about total feed and  daily feed across the selected range of dates.
-->
<template>
  <layout-pondlogs
    v-loading="loading"
    element-loading-background="white"
    headerType="card"
    :subHeader="false"
    containerType="card"
    class="feed-monitor-page"
  >
    <p slot="custom-title" class="layout-title">
      <span class="header__style">{{ $t("Daily_Feed") }}</span>
    </p>
    <template slot="header">
      <el-checkbox-group fill="#0A2463" text-color="#0A2463" :min="1" :max="2" v-model="feedSelection" class="radio-container">
        <el-checkbox v-for="item in feedOption" :label="item.id" :key="item.id" @change="handleCheckboxChange($event, item.id)">{{ $t(item.label) }}</el-checkbox>
      </el-checkbox-group>
      &nbsp;
      <er-date-picker
        size="mini"
        :timeZoneString="getUserTimeZoneString"
        popper-class="card-chart__date-picker"
        :value="getDateRangeForFeedGraph"
        :format="this.upm__getFormatDateString"
        :value-format="datePickerFormat"
        :disableDateMethod="disableDateMethod"
        :arrShortcuts="arrDatePickerShortCuts"
        @input="handleDateRangeChange"
      />
      &nbsp;
      <el-col :span="4.3" :offset="0" tag="span" class="total-search">
        <div class="search-bar" :title="$t('Comn_search')">
          <el-input
            class="search-bar-size"
            v-model="pondSearch"
            size="mini"
            :placeholder="$t('Pond_search_pond')"
            @change="handelSearchChange"
          ></el-input>
        </div>
      </el-col>
    </template>
    <el-row slot="layout-pondlogs-scrollable-main">
      <div v-if="getLoading">
        <loader></loader>
      </div>
      <er-data-tables
        :table-data="tableData"
        :columns="columns"
        :key="$i18n.locale"
        v-else
        type="white-header-table"
        :el-table-props="elTableProps"
        :action-column="false"
        unique-id="feed-table-monitor"
        ref="feedDataTable"
        :searchOnTableData="pondSearch"
      >
        <template v-slot:title="{ row }">
            <el-tooltip
                  popperClass="st-settings-table-el-tooltip__popper"
                  effect="light"
                  placement="top"
                  :content="$t('pond-area') + ' : ' + ftm__formatEmpty(row.data.size) + ' ' + row.data.units"
                >
          <er-highlight-search-component
            :highlightStrings="row.data.highlightStrings"
            :individualRowData="row.data"
            keyName="title"
            :withoutHighlightStrings="row.data.title"
          />
            </el-tooltip>
        </template>
        <template v-slot:datacell_header="{ header }">
          <!-- <div class="new" @mouseenter="pondNameHoverState = 'enter'" @mouseleave="pondNameHoverState = 'leave'"  v-if="header.column.id === 'title'">{{ header.column.label }}</div> -->
          <div class="header-row-cell">{{ header.column.label }}</div>
        </template>
        <template v-slot:datacell="{ row }">
          <div
            class="row-cell"
            :title="
              mapManagedByToMode(getCellValue(row.data[row.path], 'mode'))
            "
            :style="
              `--color:${getPondColor(
                row.data[row.path]
              )};--background-color:${getPondColor(row.data[row.path])}20`
            "
          >

            <p v-if="showDFeed">
              {{
                ftm__formatEmpty(
                  ftm__digitPrecision(
                    getCellValue(row.data[row.path], "dispensed_feed"),
                    0
                  ),
                  true,
                    "--",
                    false
                )
              }}
            </p>
            <p v-if="showFeedPerArea">
              {{
                ftm__formatEmpty(
                  ftm__digitPrecision(
                    getCellValue(row.data[row.path], getFeedAreaKeyByUserPreferences),
                    1
                  ),
                   true,
                    "--",
                    false
                )
              }}
            </p>
            <p v-if="showFeed">
              {{
                ftm__formatEmpty(
                  ftm__digitPrecision(
                    getCellValue(row.data[row.path], "feed"),
                    0
                  ),
                    true,
                    "--",
                    false
                )
              }}
            </p>
          </div>
        </template>
      </er-data-tables>
    </el-row>
  </layout-pondlogs>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import Pond from "@/model/pond";
import Loader from "@/components/base/Loader";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";

export default {
  name: "feedInfoTab",
  mixins: [
    errorHandlerMixin,
    filtersMixin,
    datesHandlerMixin,
    userPreferenceMixin
  ],
  components: { Loader },
  data: function() {
    return {
      dateSelected: [],
      datePickerFormat: "yyyy-MM-dd",
      pondNameHoverState: "",
      loading: false,
      feedSelection: ["dFeed"],
      pondSearch: "",
      feedOption: [
      { label: 'Comn_total_feed', id: 'totalFeed' },
      { label: 'feed-area', id: 'feedPerArea' }
    ]
    };
  },
  async mounted() {
    this.initComponent();
    const value = JSON.parse(localStorage.getItem("FeedSelectioNMonitorPage"));
    if (value !== null && value !== undefined && value.length > 0) {
      this.feedSelection = value;
    }
    // this.feedOption =
    // console.log('value', value);
  },
  beforeDestroy() {
    this.$store.dispatch("CANCEL_PENDING_REQUESTS");
  },
  computed: {
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation"
    }),
    ...mapGetters("feedInfo", {
      getFeedData: "getFeedData",
      dateRangeForFeedGraph: "getDateRangeForFeedGraph",
      getMinStartCultureDate: "getMinStartCultureDate",
      getLoading: "getLoading"
    }),
     getFeedAreaKeyByUserPreferences() {
      const areaToKeyMap = {
        ac: "feed_per_acre",
        ha: "feed_per_hectare"
      };
      return areaToKeyMap[this.upm__getPondSizeUnits];
    },
    getPondColor: function () {
      return (feedObj) => {
        if (!feedObj) return "#000000";
        const mode = feedObj.mode;
        if (!mode) return "#000000";
        return Pond.generateColorForMode(mode).color;
      };
    },
    getCellValue: function() {
      return (value, key) => {
        return !value ? undefined : value[key];
      };
    },
    elTableProps() {
      return {
        headerCellClassName: this.headerCellName,
        height: "calc(100vh - var(--table-height))",
        defaultSort: {
          prop: "title",
          order: "ascending"
        }
      };
    },
    showBoth() {
      return this.feedSelection.length === 2 || this.feedSelection.length === 0;
    },
    // showTotalFeed() {

    // },
    showFeedPerArea() {
       if (this.feedSelection !== undefined && this.feedSelection.length > 0) {
        return this.feedSelection.includes('feedPerArea');
      }
      return false;
    },
    showDFeed() {
      if (this.feedSelection !== undefined && this.feedSelection.length > 0) {
        return this.feedSelection.includes("dFeed") && !this.feedSelection.includes('feedPerArea');
      }
      return false;
    },
    showFeed() {
      if (this.feedSelection !== undefined && this.feedSelection.length > 0) {
        return this.feedSelection.includes("totalFeed");
      }
      return false;
    },
    getDateRangeForFeedGraph() {
      return this.dateRangeForFeedGraph;
    },
    columns() {
      const locale = this.$i18n.locale;
      const dateRange = this.getDateRangeForFeedGraph;
      const newColumns = {
        title: {
          prop: "title",
          id: "title",
          label: this.$t("Comn_pond_name"),
          sortable: true,
          sortMethod: (a, b) => {
            return this.$commonUtils.alphaNumericComparator(a.title, b.title);
          },
          fixed: true,
          enableSearch: true,
          searchProperty: "title",
          minWidth: "120px"
        }
      };
      const pdateRange = [dateRange[0], dateRange[1]].map(x =>
        this.dhm__dateUtilsLib.parse(x, this.datePickerFormat, new Date())
      );
      const dateRangeDiff = this.dhm__dateUtilsLib.differenceInDays(
        pdateRange[1],
        pdateRange[0]
      );
      for (var i = 0; i <= dateRangeDiff; i++) {
        const dateObj = this.dhm__dateUtilsLib.parse(
          dateRange[1],
          this.datePickerFormat,
          new Date()
        );
        const utcDate = this.dhm__dateUtilsLib.zonedTimeToUtc(
          this.dhm__dateUtilsLib.subtract(dateObj, { days: i }),
          this.getUserTimeZoneString
        );
        const formatString = this.dhm__dateUtilsLib.getFormatByTimeZoneOnUTC(
          this.dhm__dateUtilsLib.toISOString(utcDate),
          this.upm__getFormatDateString,
          this.getUserTimeZoneString,
          { locale }
        );
        const colValue = this.dhm__dateUtilsLib.toISOString(utcDate);
        newColumns[colValue] = {
          prop: colValue,
          slot: "dataCell",
          label: formatString,
          minWidth: "100px"
        };
      }
      return newColumns;
    },
    tableData() {
      console.log('getFeedData', this.getFeedData);
      const data = this.getFeedData.map(({ title, size, units, values }) => {
        var result = {};
        for (var i = 0; i < values.length; i++) {
          result[values[i].newDate] = values[i];
        }
        result.title = title;
        result.size = size;
        result.units = units;
        return result;
      });
      return data;
      //   return [];
    },
    arrDatePickerShortCuts: function() {
      return [
        this.dhm__dateUtilsLib.getDatePickerShortcut(
          0,
          "days",
          this.$t("Comn_today"),
          false,
          "TODAY"
        ),
        this.dhm__dateUtilsLib.getDatePickerShortcut(2, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(7, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(15, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(30, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(2, "months")
      ];
    }
  },
  watch: {
    feedSelection: function (val) {
      localStorage.setItem('FeedSelectioNMonitorPage', JSON.stringify(this.feedSelection));
    },
    pondNameHoverState(val) {
      console.log('val', val);
    }
  },
  methods: {
    ...mapActions("feedInfo", {
      fetchActivePonds: "fetchActivePonds",
      changeDateRangeInGraph: "changeDateRangeInGraph",
      changeAllGraphLoadingStatus: "changeAllGraphLoadingStatus",
      changeLocation: "changeLocation"
    }),
    ...mapMutations("feedInfo", {
      SET_LOADING: "SET_LOADING"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handelSearchChange() {
      if (this.pondSearch !== '') {
        this.mixPanelEventGenerator({ eventName: "Feeding - Monitor - Search" });
      }
    },
    handleCheckboxChange(value, data) {
      if (value === true && data === 'totalFeed') {
        this.mixPanelEventGenerator({ eventName: "Feeding - Monitor - Total Feed" });
      } else if (value === true && data === 'feedPerArea') {
        this.mixPanelEventGenerator({ eventName: "Feeding - Monitor - Feed/Area" });
      }
    },
    handlePondTitleMouseEnter(event) {
      console.log('mouse enter', event);
    },
    handlePondTitleMouseLeave(event) {
      console.log('mouse leave', event);
    },
    disableDateMethod: function (time) {
      const soc = this.getMinStartCultureDate;
      const startDate = this.dhm__dateUtilsLib.startOfDay(
        this.dhm__dateUtilsLib.utcToZonedTime(
          this.dhm__dateUtilsLib.parseISO(soc),
          this.getUserTimeZoneString
        )
      );
      const endDate = this.dhm__dateUtilsLib.endOfDay(
        this.dhm__getTodayInUserTZ
      );
      const currDate = time;
      const paramObj = {
        dateRange: [startDate, endDate],
        date: currDate,
        timeZone: this.getUserTimeZoneString,
        actionsOnDate: [],
        actionsOnDateRangeItem: []
      };
      return !this.dhm__dateUtilsLib.queryInDateRange(paramObj);
    },
    mapManagedByToMode: function(value) {
      if (value === "SHRIMP_TALK") {
        return this.$t("PM_automatic");
      } else if (value === "HYBRID") {
        return this.$t("hybrid");
      } else if (value === "FARMER") {
        return this.$t("Comn_schedule");
      } else if (value === "MANUAL") {
        return this.$t("PM_sch_mode4");
      } else if (!value) {
        return "--";
      }
    },
    async initComponent() {
      try {
        await this.changeLocation(this.getCurrUserLocation);
        await this.fetchActivePonds();
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.feedDataTable) {
            this.$refs.feedDataTable.$refs.dataTables.$refs.elTable.doLayout();
          }
        });
        this.SET_LOADING(false);
      }
    },
    async handleDateRangeChange(dateKey) {
      try {
        const data = {
          date: dateKey
        };
        await this.changeDateRangeInGraph(data);
        this.mixPanelEventGenerator({ eventName: "Feeding - Monitor - Date Filter" });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.feedDataTable) {
            this.$refs.feedDataTable.$refs.dataTables.$refs.elTable.doLayout();
          }
        });
        this.SET_LOADING(false);
        // this.changeAllGraphLoadingStatus(false);
      }
    },

    headerCellName() {
      return "header-row-cell";
    }
  }
};
</script>

<style  lang="scss">
@import "../../styles/mixins.scss";
@mixin align-cell-content {
  margin-right: 15px;
}
.radio-container {
  .is-checked .el-checkbox__label {
    color: #0A2463 !important;
}
.el-checkbox__label {
   color: #0A2463 !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner{
    background-color: #0A2463 !important;
    border-color: #0A2463 !important;
}
}
.feed-monitor-page {
  .el-table__fixed {
    width: 242px;
    height: calc(100vh - 192px) !important;
  }
  @include responsiveProperty(--table-height, 178px, 182px, 198px);
  &.pondlogs-default-layout {
    padding: 0px;
  }
  .el-range-editor--mini {
    // @include responsiveProperty(flex-basis, 25%, 20%, 15%);
  }
  .el-card__body {
    padding: 0px 10px;
  }
  .row-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    color: var(--color);
    background-color: var(--background-color);
    font-weight: bold;
    width: 100%;
    @include responsiveProperty(
      font-size,
      $font_size_1,
      $font_size_2,
      $font_size_2
    );
    p + p {
      &::before {
        content: "/";
        padding-left: 4px;
      }
    }
  }
  .search-bar {
    display: flex;
    align-items: center;
    justify-content: center;

    .el-card__header {
      padding: 10px 10px 10px 20px;
      border-bottom: unset;
      // position: absolute;
      margin-top: -1px;
      width: 100%;
      background-color: white;
      z-index: 1100;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
      line-height: 1.5;
      p.layout-title {
        @include responsiveProperty(
          font-size,
          $app_font_size_1,
          $app_font_size_2,
          $app_font_size_3
        );
        font-weight: 700;
        color: #0a2463;
      }
      & > .el-card__sub-header {
        padding-top: 10px;
      }
    }
    .el-card__body {
      padding: 0px 10px;
    }
    .row-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      color: var(--color);
      background-color: var(--background-color);
      font-weight: bold;
      @include responsiveProperty(
        font-size,
        $font_size_1,
        $font_size_2,
        $font_size_2
      );
      p + p {
        &::before {
          content: "/";
          padding-left: 4px;
        }
      }
    }
    .el-table .el-table__body-wrapper {
      height: calc(100% - 36px) !important;
    }
    .search-bar {
      display: flex;
      align-items: center;
      justify-content: center;

      input {
        background: white;
        border: 0px solid #dcdfe6;
        border-radius: 8px;
        color: black !important;
      }
      img {
        cursor: pointer;
        width: 16px;
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 6px;
      }

      .search_icon {
        @include responsiveProperty(
          font-size,
          $app_font_size_1,
          $app_font_size_2,
          $app_font_size_3
        );
      }
    }
    .search-bar-size {
      width: 180px;
    }
    .evencell {
      background-color: #f9faff;
    }
    .el-checkbox-group {
      .el-checkbox__label {
        @include responsiveProperty(
          font-size,
          $app_font_size_1,
          $app_font_size_2,
          $app_font_size_3
        );
        line-height: 1;
      }
      .el-checkbox__inner:hover .el-checkbox__inner {
        border-color: #155dbe;
      }
      .el-checkbox__input {
        align-self: center;
        &.is-checked + .el-checkbox__label {
          color: #606266;
        }
        &.is-checked .el-checkbox__inner,
        &.is-indeterminate .el-checkbox__inner {
          background-color: #155dbe;
          border-color: #155dbe;
        }
        &.is-checked::after {
          color: #606266;
        }
      }
    }
  }
}
</style>
