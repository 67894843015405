<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: feedEntryTable.vue
Description: This file contains UI components used to enter feed data in pond feeding tab of schedules
-->
<template>
  <el-table
    :data="getArrScheds"
    ref="scheduleTable"
    cell-class-name="schedule-cell"
    @selection-change="handleSelectionChange"
    header-row-class-name="card-row-table__header-row"
    header-cell-class-name="feed-entry-class-header"
    row-class-name="card-row-table__row"
    row-key="_id"
    height="calc(100vh - var(--height-gutter))"
    class="feed-entry-table card-row-table"
    :empty-text="$t('PM_no_pond_sch_mode')"
  >
    <el-table-column width="25px" fixed>
      <template slot="header" slot-scope="{ $index }">
        <el-checkbox
          :key="$index"
          :value="checkAll"
          :indeterminate="isIndeterminate"
          @change="handleCheckAllChange"
        ></el-checkbox>
      </template>
      <template slot-scope="scope">
        <el-checkbox
          :value="scope.row.selected"
          @change="handleCheckedSchedulesChange($event, scope.row.pond_id)"
        ></el-checkbox>
      </template>
    </el-table-column>
    <el-table-column
      :min-width="100"
      :label="tableColumns['pond_title'].label"
      fixed
    >
      <template slot-scope="{ row }"
        ><span style="white-space: break-spaces">{{ row.pond_title }}</span>
      </template>
    </el-table-column>
    <el-table-column :min-width="130" :label="tableColumns['feed_type'].label">
      <template slot-scope="{ row }">
        <er-select
          :value="row.feed_type_id"
          size="mini"
          :placeholder="$t('select_feed')"
          @change="handleChangeInFeedType($event, row)"
        >
          <el-option
            v-for="feedType in getArrFeedTypes"
            :key="feedType._id"
            :label="`${feedType.name} ${feedType.feed_type === undefined ? '' : '-'} ${feedType.feed_type === undefined ? '' :  feedType.feed_type}`"
            :value="feedType._id"
          ></el-option>
        </er-select>
      </template>
    </el-table-column>
    <el-table-column :min-width="100">
      <template slot="header">
        <center>{{ tableColumns["total_feed"].label }}</center>
      </template>
      <template slot-scope="{ row }">
        <el-input-number
          :controls="false"
          :precision="1"
          :value="row.feed"
          size="mini"
          @change="handleChangeTotalFeedAtPond($event, row)"
          :min="0"
        ></el-input-number>
      </template>
    </el-table-column>
    <el-table-column :min-width="100" prop="ocf">
      <template slot="header">
        <center>{{ tableColumns["ocf"].label }}</center>
      </template>
      <template slot-scope="{ row }">
        <el-input-number
          :controls="false"
          :value="row.ocf"
          :precision="0"
          :step="50"
          size="mini"
          @change="handleChangeInOCF($event, row)"
          :min="200"
          :max="2500"
        ></el-input-number>
      </template>
    </el-table-column>
    <el-table-column
      :min-width="100"
      v-if="getIsCurrentDay"
      :label="tableColumns['dispensed_feed'].label"
      align="center"
    >
      <template slot-scope="{ row }">
        <el-input-number
          :controls="false"
          :precision="1"
          :value="getDispensedFeedToPondId[row.pond_id]"
          :disabled="true"
          :min="0"
          size="mini"
        ></el-input-number>
      </template>
    </el-table-column>
    <el-table-column
      v-for="(ts, index) in getSelectedFtTS"
      :key="index"
      :minWidth="80"
    >
      <!-- `${updated} aadf ${index}` -->
      <template slot="header">
        <center>
          <p class="small-text">
            {{ `${$t("s")}${index + 1}` }}: {{ ts.feed_percentage }}%
          </p>
          <p class="small-text">{{ formatTime(ts.s_time) }}</p>
        </center>
      </template>
      <template slot-scope="{ row }">
        <div class="time-slot-cell" v-if="row.time_slots[index]">
          <el-input-number
            :controls="false"
            :precision="2"
            :min="0"
            size="mini"
            :value="row.time_slots[index].feed"
            :key="row.time_slots[index]._id"
            :disabled="disableTimeSlot(row.time_slots, index, row.pond_id)"
            @change="handleChangeTFAtTS($event, row, index)"
          ></el-input-number>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";

export default {
  mixins: [datesHandlerMixin],
  data: function () {
    return {
      componentName: "timeSlotPondMotherList",
      selectedPonds: [],
      oldData: [],
      feedTypeChanged: []
    };
  },
  watch: {
    getSelectedFtTS: function () {
      this.$nextTick(() => {
        this.$refs.scheduleTable.doLayout();
      });
    },
    getArrScheds() {
      this.oldData.reduce((acc, curr, index) => {
        if ( this.getArrScheds[index].selected && this.getArrScheds[index].feed_type_id !== this.oldData[index].feed_type_id ) {
          this.feedTypeChanged[index] = true;
        } else {
          this.feedTypeChanged[index] = false;
        }
        return acc;
      }, [])
      this.handleChangeFeedType({ feedTypeChanged: this.feedTypeChanged })
    }
  },
  mounted() {
    this.oldData = this.$lodash.cloneDeep(this.getArrScheds);
  },
  computed: {
    ...mapGetters("pondFeeding", {
      getArrScheds: "getArrScheds",
      getSelectedFtTS: "getSelectedFtTS",
      getArrFeedTypes: "getArrFeedTypes",
      getIsCurrentDay: "getIsCurrentDay",
      getDispensedFeedToPondId: "getDispensedFeedToPondId",
      groupPondIdToRunningSchedules: "groupPondIdToRunningSchedules",
      isTSValidInGivenPond: "isTSValidInGivenPond"
    }),
    checkAll: function () {
      return this.getArrScheds.every((sched) => sched.selected === true);
    },
    isIndeterminate: function () {
      return (
        this.getArrScheds.some((sched) => sched.selected === true) &&
        !this.checkAll
      );
    },
    templateTimeSlots() {
      return this.propTemplateTimeSlots;
    },
    tableColumns() {
      return {
        pond_title: {
          label: this.$t("Comn_pond_name")
        },
        total_feed: {
          label: "TF(kgs)"
        },
        feed_type: {
          label: this.$t("Comn_feed_type")
        },
        ocf: {
          label: "OCF(g)"
        },
        dispensed_feed: {
          label: this.$t("Comn_df")
        }
      };
    }
  },
  methods: {
    ...mapActions("pondFeeding", {
      changeSelectionPondSched: "changeSelectionPondSched",
      changeTFAtTSInSched: "changeTFAtTSInSched",
      handleChangeFeedType: "handleChangeFeedType",
      changeTFAtSched: "changeTFAtSched",
      changeFeedTypeAtSched: "changeFeedTypeAtSched",
      changeSelectionOfAllPondSchedules: "changeSelectionOfAllPondSchedules",
      changeOCFAtSched: "changeOCFAtSched"
    }),
    formatTime(value) {
      return this.dhm__dateUtilsLib.formatDate(
        this.dhm__dateUtilsLib.parse(value, "HH:mm", new Date()),
        "HH:mm"
      );
    },
    disableTimeSlot: function (ts, index, pondId) {
      const tsSTime = ts[index].s_time_secs;
      const currTime = this.dhm__dateUtilsLib.getCurrTimeSecsInGivenTZ(
        this.getUserTimeZoneString
      );
      if (!this.getIsCurrentDay) return false;
      if (tsSTime > currTime && this.isTSValidInGivenPond(ts[index], pondId)) {
        return false;
      }
      return true;
    },
    getPondName: function (pond_id) {
      return this.mapPondIdPond.get(pond_id);
    },
    hasDispensedFeedField(schedule) {
      return schedule.dispensed_feed > -1;
    },
    getTotalArrWithObjField(arrElements, field) {
      return arrElements
        .map((element) => {
          return element[field];
        })
        .reduce((acc, curr) => {
          return acc + curr;
        });
    },
    getName(scope) {
      return scope.column;
    },
    handleCheckAllChange(isSelectedAll) {
      this.changeSelectionOfAllPondSchedules(isSelectedAll);
    },
    handleChangeInFeedType(updtVal, sched) {
      this.changeFeedTypeAtSched({ updtVal, pondId: sched.pond_id });
    },
    handleCheckedSchedulesChange(updtVal, pondId) {
      this.changeSelectionPondSched({ pondId, updtVal });
    },
    handleSelectionChange(arrSelectedPonds) {
      this.selectedPonds = arrSelectedPonds;
    },
    handleChangeTotalFeedAtPond(updtVal, schedule) {
      this.changeTFAtSched({ updtVal, pondId: schedule.pond_id });
    },
    handleChangeTFAtTS(updtVal, schedule, timeSlotIndex) {
      this.changeTFAtTSInSched({
        pondId: schedule.pond_id,
        tsPos: timeSlotIndex,
        updtVal
      });
    },
    handleChangePMFeed(totalPMsFeed, schedule, timeSlotIndex) {
      schedule.timeSlots[timeSlotIndex].total_feed = totalPMsFeed;
      schedule.total_feed = this.getTotalArrWithObjField(
        schedule.timeSlots,
        "total_feed"
      );
      schedule.total_feed = schedule.total_feed.toFixed(2);
    },
    handleChangeInOCF(updtVal, schedule) {
      this.changeOCFAtSched({ updtVal, pondId: schedule.pond_id });
    }
  }
};
</script>

<style lang="scss">
$font-color: $button-color;
$background-color: #66b3ff;
$disabled-input-color: #f4f5f9;
$disabled-font-color: #140f26;
$input-height: 30px;
$table-row-box-shadow: #e3d1d1;
.feed-entry-table-popover {
  padding-top: 0px !important;
}
.feed-entry-table {
  // width: 54rem;
  &.card-row-table {
    .card-row-table__header-row {
      color: $font-color;
    }
    &.el-table td > .cell,
    &.el-table th:not(:nth-child(13)) > .cell {
      padding-left: 5px;
      padding-right: 5px;
    }
    &.el-table .el-table__row .schedule-cell:nth-child(14) {
      padding-right: 10px;
    }

    &.el-table td,
    &.el-table th.is-leaf {
      padding-top: 4px;
      padding-bottom: 4px;
      border-bottom-color: #6c7b8a30;
    }
    td .cell,
    th .cell {
      @include responsiveProperty(
        font-size,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
      // white-space: break-spaces;
      .el-input__inner,
      .el-tag {
        @include responsiveProperty(
          font-size,
          $app_font_size_small,
          $app_font_size_1,
          $app_font_size_2
        );
      }
    }
    // .el-table__header-wrapper,
    // .el-table__body-wrapper {
    //   margin-right: 5px;
    //   margin-left: 5px;
    // }
  }
  .el-tag--primary {
    border-color: $background-color;
    background-color: $background-color;
  }
  .feed-entry-class-header .cell {
    word-break: break-word;
  }
  .el-input {
    .el-input__inner {
      // height: $input-height;
      border: 1px solid #c4e2ff;
    }
    .el-input__icon {
      line-height: 30px;
    }
  }
  .schedule-cell {
    .cell .time-slot-cell {
      position: relative;
      // height: 60px;
      // width: 80px;
      // padding: 0px !important;
      i {
        position: absolute;
        right: 0px;
        z-index: 1;
      }
    }
    .el-input-number {
      width: unset;
      &.is-without-controls .el-input__inner {
        padding-left: 5px;
        padding-right: 5px;
        text-align: center;
      }
      &.is-disabled {
        .el-input__inner {
          background-color: $disabled-input-color;
          color: $disabled-font-color;
        }
      }
    }
  }
  @include responsiveProperty(--height-gutter, 152px, 160px, 177px);
}
</style>
