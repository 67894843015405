<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: mapPondGraphs.vue
Description: This file contains UI components that are shown in pond tab in maps page
-->
<template>
  <el-scrollbar
    class="map-side-menu-graphs map-side-menu-graphs-scroll"
    viewClass="map-side-menu-graphs-scroll-view"
    wrapClass="map-side-menu-graphs-scroll-wrap"
  >
    <map-pg-graph></map-pg-graph>
    <map-pond-day-feed></map-pond-day-feed>
    <map-hourly-feed-graph></map-hourly-feed-graph>
  </el-scrollbar>
</template>

<script>
import mapPgGraph from "./graphs/mapPgGraph";
import mapHourlyFeedGraph from "./graphs/mapHourlyFeedGraph";
import mapPondDayFeed from "./graphs/mapPondDayFeed";
export default {
  components: {
    mapPgGraph,
    mapHourlyFeedGraph,
    mapPondDayFeed
  }
};
</script>

<style lang="scss">
.map-side-menu-graphs {
  width: 100%;
  height: calc(100vh - 200px);

  padding-bottom: 10px;
  .map-side-menu-graphs-scroll-wrap {
    height: 100%;
    overflow-x: auto;
  }
  .map-side-menu-graphs-scroll-view {
    margin-right: 10px;
    // @include responsiveProperty(margin-bottom, 1.5em, 0.625rem, 0.625rem);
  }
}
</style>
