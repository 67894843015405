<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: communication.vue
Description: Thus file contains UI component show as signal icon used in feeding PondMother tab
-->
<template>
  <span>
    <el-tooltip :content="title" placement="top">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.72466 10.3357C2.54664 10.2537 2.33744 10.2827 2.18758 10.4097L0.176012 12.1196C0.06437 12.2146 0 12.3536 0 12.4996V15.4995C0 15.7755 0.225295 15.9995 0.502891 15.9995H2.51445C2.79205 15.9995 3.01734 15.7755 3.01734 15.4995V10.7896C3.01734 10.5947 2.90269 10.4177 2.72466 10.3357Z"
          :fill="color"
        />
        <path
          d="M6.74712 6.90597C6.57011 6.82397 6.3599 6.85297 6.21004 6.97997L4.19847 8.68993C4.08683 8.78493 4.02246 8.92393 4.02246 9.06992V15.4998C4.02246 15.7758 4.24776 15.9998 4.52535 15.9998H6.53692C6.81451 15.9998 7.03981 15.7758 7.03981 15.4998V7.35996C7.03981 7.16497 6.92515 6.98697 6.74712 6.90597Z"
          :fill="color"
        />
        <path
          d="M10.771 3.4758C10.593 3.3938 10.3838 3.4228 10.234 3.54979L8.2224 5.25975C8.11076 5.35475 8.04639 5.49375 8.04639 5.63975V15.4995C8.04639 15.7755 8.27168 15.9995 8.54928 15.9995H10.5608C10.8384 15.9995 11.0637 15.7755 11.0637 15.4995V3.92979C11.0637 3.73479 10.9501 3.55779 10.771 3.4758Z"
          :fill="color"
        />
        <path
          d="M14.794 0.0463758C14.617 -0.0356223 14.4058 -0.00762293 14.2569 0.120374L12.2453 1.83033C12.1337 1.92533 12.0693 2.06433 12.0693 2.21032V15.5C12.0693 15.776 12.2946 16 12.5722 16H14.5838C14.8614 16 15.0867 15.776 15.0867 15.5V0.500365C15.0867 0.30537 14.973 0.127374 14.794 0.0463758Z"
          :fill="color"
        />
        <!-- <title>{{ title }}</title> -->
      </svg>
    </el-tooltip>
  </span>
</template>

<script>
export default {
  props: {
    color: { default: "#33A02C" },
    title: { default: "" }
  }
};
</script>

<style lang="scss" scoped></style>
