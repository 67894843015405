<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: mapHourlyFeedGraph.vue
Description: This file is the chart component that displays hourly feed dispsensed in the pond in the maps page. It differentiates feeding modes  with the help of colors. Here x axis is the feeding date and y axis is the amount of feed dispensed in different modes( manual, schedule, automatic)
-->
<template>
  <el-row
    v-loading="loading"
    element-loading-background="white"
    class="map-hourly-feed-graph"
  >
    <layout-toolbar gap="5">
      <b class="map__hourly__header">{{ $t("pond_hourly_feed") }}</b>
      <div class="filler"></div>
      <el-select :value="dataViewOptions" size="mini" @change="handleCommand">
        <el-option :label="$t('Pond_raw_data')" value="RAW_DATA"></el-option>
        <el-option :label="$t('Comn_avrg_data')" value="AVG_DATA"></el-option>
      </el-select>
      <er-date-picker
        type="daterange"
        unlink-panels
        size="mini"
        v-model="dateRange"
        :disableDateMethod="disableDateMethod"
        :format="upm__getFormatDateString"
        :timeZoneString="getUserTimeZoneString"
        :availableInterval="availableInterval"
        @change="handleValueChange"
      ></er-date-picker>
    </layout-toolbar>
    <el-row :key="$i18n.locale">
      <high-charts
        :options="chartOptions"
        constructor-type="stockChart"
      ></high-charts>
    </el-row>
  </el-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import mapChartMixin from "@/mixins/mapChartMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import { pondHourlyGraphConfig } from "./chartOptions";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
export default {
  mixins: [
    errorHandlerMixin,
    mapChartMixin,
    datesHandlerMixin,
    userPreferenceMixin
  ],
  data: function() {
    return {
      feed_hourly_logs: [],
      dataViewOptions: "RAW_DATA",
      dateRange: [],
      params: {
        from_date: "",
        to_date: "",
        month_of: null,
        week_of: null
      },
      chartOptions: { ...pondHourlyGraphConfig },
      loading: false
    };
  },
  computed: {
    ...mapGetters("user", {
      getPreferredUnits: "getPreferredUnits"
    }),
    ...mapGetters("googleMaps", {
      getPondHourlyFeedGraph: "getPondHourlyFeedGraph"
    }),
    dataViewOptionsStr() {
      return {
        RAW_DATA: "Pond_raw_data",
        AVG_DATA: "Comn_avrg_data"
      };
    },
    defaultDateObjDtRangeForCurrCulture() {
      const daysAvailable = this.getCurrentCulture.doc;
      return daysAvailable > 2
        ? this.dhm__dateUtilsLib.getDateRangeFromRefDate({
            referenceDate: this.dhm__dateUtilsLib.getCurrDateInGivenTZ(
              this.getUserTimeZoneString
            ),
            distanceFromRefDate: [
              { action: "subtract", params: [{ days: 2 }] }
            ],
            timeZone: this.getUserTimeZoneString,
            actionsOnReferenceDate: [],
            actionsOnDateRangeItem: []
          })
        : this.getCultureDateRange;
    }
  },
  methods: {
    ...mapActions("googleMaps", {
      fetchHourWisePondFeed: "fetchHourWisePondFeed"
    }),
    async initComponent(pondId) {
      this.loading = true;
      const yAxis = "Comn_feed_kg";
      try {
        await this.fetchHourWisePondFeed({
          deviceType: "pond",
          params: this.params
        });
        this.feed_hourly_logs = this.getPondHourlyFeedGraph || [];
        this.handleCommand(this.dataViewOptions);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
        this.initAxisTextKeys("Comn_date", yAxis);
        this.initChartLang();
      }
    },
    pondHourlyFeed(pondFeedDaysWise = [], dateRange) {
      const milliSecs = date => date.getTime();
      const dayFeedSchedule = {};
      const dayFeedAutomatic = {};
      pondFeedDaysWise.forEach(dayFeed => {
        const dateISOStr = dayFeed.date;
        dayFeed.time_slots.forEach(timeSlotFeed => {
          const correctDate = this.dhm__dateUtilsLib.parseISO(dateISOStr);
          const dateTimeFeed = milliSecs(
            this.dhm__dateUtilsLib.add(correctDate, {
              seconds: timeSlotFeed.s_time_in_seconds
            })
          );
          timeSlotFeed.pond_mothers.reduce(
            (acc, pm) => {
              if (acc.dayFeedAutomatic[dateTimeFeed] === undefined) {
                acc.dayFeedAutomatic[dateTimeFeed] = 0;
              }
              if (acc.dayFeedSchedule[dateTimeFeed] === undefined) {
                acc.dayFeedSchedule[dateTimeFeed] = 0;
              }
              if (pm.mode === "AUTOMATIC") {
                acc.dayFeedAutomatic[dateTimeFeed] += pm.dispensed_feed;
              } else {
                acc.dayFeedSchedule[dateTimeFeed] += pm.dispensed_feed;
              }
              return acc;
            },
            { dayFeedSchedule, dayFeedAutomatic }
          );
        });
      });
      let arrDayFeedSchedule = Object.keys(dayFeedSchedule).map(key => {
        return {
          x: Number(key),
          y: dayFeedSchedule[key]
        };
      });
      let arrDayFeedAutomatic = Object.keys(dayFeedAutomatic).map(key => {
        return {
          x: Number(key),
          y: dayFeedAutomatic[key]
        };
      });
      arrDayFeedSchedule = arrDayFeedSchedule.sort((a, b) => a.x - b.x);
      arrDayFeedAutomatic = arrDayFeedAutomatic.sort((a, b) => a.x - b.x);
      return {
        feedSchedule: arrDayFeedSchedule,
        feedAutomatic: arrDayFeedAutomatic
      };
    },
    pondHourlyAvgData: function(pondFeedDaysWise = [], dateRange) {
      const groupByFeedSchedule = {};
      const groupByFeedAutomatic = {};
      const milliSecs = date => date.getTime();
      const getDate = dateStr => this.dhm__dateUtilsLib.parseISO(dateStr);
      const dateRangeDiff =
        this.dhm__dateUtilsLib.differenceInDays(
          getDate(dateRange[1]),
          getDate(dateRange[0])
        ) + 1 || 1;
      const isoString = dateRange[0] + "T00:00:00.000Z";
      pondFeedDaysWise.forEach(dayFeed => {
        const date = milliSecs(new Date(isoString));
        dayFeed.time_slots.forEach(timeSlotFeed => {
          const dateTimeFeed = date + timeSlotFeed.s_time_in_seconds * 1000;
          if (groupByFeedAutomatic[dateTimeFeed] === undefined) {
            groupByFeedAutomatic[dateTimeFeed] = 0;
          }
          if (groupByFeedSchedule[dateTimeFeed] === undefined) {
            groupByFeedSchedule[dateTimeFeed] = 0;
          }
          timeSlotFeed.pond_mothers.forEach(pm => {
            if (pm.mode === "AUTOMATIC") {
              groupByFeedAutomatic[dateTimeFeed] += pm.dispensed_feed;
            } else {
              groupByFeedSchedule[dateTimeFeed] += pm.dispensed_feed;
            }
          });
        });
      });
      Object.keys(groupByFeedSchedule).forEach(key => {
        groupByFeedSchedule[key] = groupByFeedSchedule[key] / dateRangeDiff;
      });
      Object.keys(groupByFeedAutomatic).forEach(key => {
        groupByFeedAutomatic[key] = groupByFeedAutomatic[key] / dateRangeDiff;
      });
      let resultGroupByFeedSchedule = Object.keys(groupByFeedSchedule).map(
        key => {
          return {
            x: Number(key),
            y: groupByFeedSchedule[key]
          };
        }
      );
      let resultGroupByFeedAutomatic = Object.keys(groupByFeedAutomatic).map(
        key => {
          return {
            x: Number(key),
            y: groupByFeedAutomatic[key]
          };
        }
      );
      resultGroupByFeedSchedule = resultGroupByFeedSchedule.sort(
        (a, b) => a.x - b.x
      );
      resultGroupByFeedAutomatic = resultGroupByFeedAutomatic.sort(
        (a, b) => a.x - b.x
      );
      return {
        groupByFeedSchedule: resultGroupByFeedSchedule,
        groupByFeedAutomatic: resultGroupByFeedAutomatic
      };
    },
    handleValueChange: async function(currentSelectedValues) {
      this.params.from_date = currentSelectedValues[0];
      this.params.to_date = currentSelectedValues[1];
      await this.initComponent(this.pondId);
    },
    initChart() {
      let result = {};
      let schedulePMsFeed = [];
      let automaticPMsFeed = [];
      let totalSchedulePMsFeed = 0;
      let totalAutomaticPMsFeed = 0;
      if (this.feed_hourly_logs.length === 0) {
        // let x = this.generateData();
        // let x = { schedulePMsFeed: [], automaticPMsFeed: [] };
        // schedulePMsFeed = x.schedulePMsFeed;
        // automaticPMsFeed = x.automaticPMsFeed;
        this.chartOptions.series = [];
        return;
      } else {
        if (this.dataViewOptions === "AVG_DATA") {
          result = this.pondHourlyAvgData(
            this.feed_hourly_logs || [],
            this.dateRange
          );
          schedulePMsFeed = result.groupByFeedSchedule;
          automaticPMsFeed = result.groupByFeedAutomatic;
        } else if (this.dataViewOptions === "RAW_DATA") {
          result = this.pondHourlyFeed(
            this.feed_hourly_logs || [],
            this.dateRange
          );
          schedulePMsFeed = result.feedSchedule;
          automaticPMsFeed = result.feedAutomatic;
        }
        totalSchedulePMsFeed = (
          schedulePMsFeed.map(feed => feed.y) || []
        ).reduce((acc, curr) => acc + curr, 0);
        totalAutomaticPMsFeed = (
          automaticPMsFeed.map(feed => feed.y) || []
        ).reduce((acc, curr) => acc + curr, 0);
      }
      this.initChartSeries(
        automaticPMsFeed,
        schedulePMsFeed,
        totalSchedulePMsFeed,
        totalAutomaticPMsFeed
      );
    },
    initChartSeries(
      automaticPMsFeed,
      schedulePMsFeed,
      totalSchedulePMsFeed,
      totalAutomaticPMsFeed
    ) {
      this.chartOptions.series = [];
      this.chartOptions.series.push({
        name: this.$t("PM_automatic"),
        data: automaticPMsFeed,
        color: "#758e32",
        type: "column",
        pointWidth: 10,
        visible: true,
        stacking: "overlap",
        stack: 0,
        dataGrouping: {
          enabled: false
        }
      });
      this.chartOptions.series.push({
        name: this.$t("Comn_schedule"),
        data: schedulePMsFeed,
        color: "#f1b727",
        type: "column",
        pointWidth: 10,
        visible: true,
        stacking: "overlap",
        stack: 0,
        dataGrouping: {
          enabled: false
        }
      });
    },
    handleCommand(command) {
      this.dataViewOptions = command;
      this.initChart();
    }
  }
};
</script>
<style lang="scss">
.map-hourly-feed-graph {
  .map__hourly__header {
    display: flex;
    padding-left: 10px;
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
  }
}
</style>
