<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: infoWindow.vue
Description: This contains UI components to show important details of the ponds on hover in maps page
-->
<template>
  <div>
    <div id="pond-details-info-container">
      <div class="iw-container" id="pond-details-info-window">
        <span class="el-row iw-heading">
          <el-row type="flex" justify="space-between" class="iw-header-row">
            <!-- :class="(pond.title || 'NA').length > 10 ? 'marquee' : ''" -->
            <el-link
              style="
                height: 25px;
                margin: 0px;
                padding: 0px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
              "
              :href="`/user/pond-details?pondId=${pond._id}`"
              :underline="false"
            >
              <div class="infotruncate">
                {{ pond.title || "NA" }}
              </div>
            </el-link>
            <el-row type="flex" style="display: inline-flex" align="middle">
              &nbsp;(
              <el-col class="value" tag="span">
                <div
                  v-upm__units.digitPrecision="getPondSizeAndUnits(pond)"
                ></div>
              </el-col>
              )
            </el-row>
          </el-row>
          <el-row type="flex" class="left-container">
            <el-col class="doc active-tag">
              <span class="circle"></span>
              <span class="value" :data-units="$t('Comn_days')">
                <p>{{ isPondActive ? daysOfCulture : "- - -" }}</p>
              </span>
            </el-col>

            <er-button
              v-if="isPondActive"
              type="text"
              size="mini"
              btnType="edit"
              :showIcon="true"
              ref="actionBtn2"
              class="btn-pond-edit"
              :disabled="isReadOnly"
            >
            </er-button>
            <er-button
              v-else
              type="text"
              size="mini"
              ref="actionBtn1"
              btnType="circle_activate"
              :showIcon="true"
              class="btn-pond-edit"
              :disabled="isReadOnly"
            >
            </er-button>
          </el-row>
        </span>
        <div class="iw-body">
          <el-row class="iw-body-row" type="flex">
            <el-col tag="span" class="abw iw-body-col">
              <el-col tag="span">
                <el-row tag="span" class="heading">{{ $t("ABW_abw") }}</el-row>
                <el-row tag="span" class="value" data-units="g">{{
                  ftm__digitPrecision(
                    pond.initial_abw_id ? pond.initial_abw_id.abw : 0,
                    1
                  )
                }}</el-row>
              </el-col>
            </el-col>
            <el-col tag="span" class="do iw-body-col">
              <el-col tag="span">
                <el-row tag="span" class="heading">
                  {{ $t("Comn_do") }}
                </el-row>
                <el-row
                  tag="span"
                  class="value"
                  :data-units="pond.do ? 'mg/L' : ''"
                >
                  <p>
                    {{ ftm__formatEmpty(ftm__digitPrecision(pond.do, 1)) }}
                  </p>
                </el-row>
              </el-col>
            </el-col>
            <el-col tag="span" class="temp iw-body-col">
              <el-col tag="span">
                <el-row class="heading">{{ $t("Comn_temp") }}</el-row>
                <el-row
                  class="value"
                  :data-units="pond.temp ? `(\u00b0C)` : ''"
                >
                  {{ ftm__formatEmpty(ftm__digitPrecision(pond.temp, 1)) }}
                </el-row>
              </el-col>
            </el-col>
          </el-row>

          <el-row
            type="flex"
            class="iw-body-row"
            v-if="pond.status === 'ACTIVE' && (pond.awg || []).length > 0"
          >
            <span class="el-col el-col-4 heading">{{ $t("Comn_awg") }}:</span>
            <span
              v-for="i in 4"
              :key="i"
              :title="`AWG >> ${getDateFormat(pond.awg[4 - i])}`"
              :class="`el-col el-col-4 value avg-wt-gain-week-${i} ${
                (pond.awg[4 - i] || { abw: 'NA' }).awg <= 0
                  ? 'avg_neg_wt_gain'
                  : 'avg_pos_wt_gain'
              }`"
            >
              {{ ftm__digitPrecision((pond.awg[4 - i] || { awg: 0 }).awg, 1) }}
            </span>
          </el-row>
          <el-row
            type="flex"
            justify="center"
            style="padding-bottom: 5px; color: #909399"
            v-else
            >{{ pond.status === "ACTIVE" ? $t('Comn_active') : $t('Comn_inactive') }}</el-row
          >
          <!-- {{ pond.pond_guards_data }} -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
export default {
  mixins: [
    errorHandlerMixin,
    filtersMixin,
    datesHandlerMixin,
    userPreferenceMixin
  ],
  props: ["map"],
  data: function () {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters("googleMaps", {
      pond: "getCurrentHoverPond",
      getLoadingStatus: "getLoadingStatus",
      getHoveredPondCurrentCulture: "getHoveredPondCurrentCulture",
      getPondIdToLatestNDOTempValues: "getPondIdToLatestNDOTempValues"
    }),
    ...mapGetters("user", {
      getUserCountryName: "getUserCountryName",
      getUpdatePermissions: "getUpdatePermissions",
      getPreferredUnits: "getPreferredUnits",
      isReadOnly: "isReadOnly"
    }),
    isPondActive() {
      return this.pond.status === "ACTIVE";
    },
    getPondSizeAndUnits() {
      return (pond) => {
        const pondAreaAndUnits = {
          value: pond.size || 0,
          previousunits: pond.units,
          units: this.getPreferredUnits.pond_area_units,
          modifiersParams: { digitPrecision: [2] }
        };
        return pondAreaAndUnits;
      };
    },
    getPondDoAndTempValues() {
      const id = this.pond._id;
      if (!id) return { do: 0, temp: 0 };
      const pondPgDetails = this.getPondIdToLatestNDOTempValues[id];
      if (!pondPgDetails.length) return { do: 0, temp: 0 };
      return pondPgDetails[0];
    },
    daysOfCulture: function () {
      const value = (this.getHoveredPondCurrentCulture || { doc: -1 }).doc;
      return value >= 0 ? value + 1 : "--";
    },
    getDateFormat() {
      return (awg) => {
        if (awg && awg.date) {
          return this.dhm__formatByTZOnUTCWithLocale(
            awg.date,
            this.$commonUtils.DATE_OBJ_DATE_FORMAT_WITH_YEAR
          );
        }
        return "NA";
      };
    },
    getPondGuardData: function () {
      const arrayPGs = this.pond.pond_guards_data || [];
      const length = arrayPGs.length;
      if (length > 0) {
        const totalDO = arrayPGs
          .map((x) => x.sensor_values.dissolved_oxygen)
          .reduce((x, y) => x + (y || 0));
        const totalTemperature = arrayPGs
          .map((x) => x.sensor_values.temperature)
          .reduce((x, y) => x + (y || 0));
        const totalpH = arrayPGs
          .map((x) => x.sensor_values.ph)
          .reduce((x, y) => x + (y || 0));
        return {
          dissolved_oxygen: totalDO / length,
          temperature: totalTemperature / length,
          ph: totalpH / length
        };
      }
      return { dissolved_oxygen: 0, temperature: 0, ph: 0 };
    },
    updatePondSizeBasedOnUnits() {
      return (pondSelected) => {
        const size = (pondSelected || { size: 0 }).size;
        const units = (pondSelected || { units: "ha" }).units;
        // const userLocation = this.getUserCountryName.toLowerCase();
        // if (userLocation === "india") {
        //   return this.$commonUtils.castPondAreaSize(size, units, "ac");
        // }
        return this.$commonUtils.castPondAreaSize(size, units, this.getPreferredUnits.pond_area_units); // "ha"
      };
    }
  },

  methods: {
    ...mapActions("pond", {
      pondLatestABWValues: "fetchPondLatestABWValues"
    })
  }
};
</script>

<style lang="scss">
$infowidow-container-parent-width: 235px;
$header-background-color: #ffffff;
$font-color: #0a2463;
.gm-style .gm-style-iw-c {
  border-radius: 5px;
  padding: 0px !important;
  min-width: $infowidow-container-parent-width !important;
}
.gm-style-iw-d {
  // max-width: $infowidow-container-parent-width !important;
  overflow: hidden !important;
}
.gm-style .gm-ui-hover-effect {
  top: 0px !important;
  right: 0px !important;
}
.gm-style-iw.gm-style-iw-c > button {
  display: none !important;
}
#pond-details-info-window {
  .active-tag {
    background: #33a02c;
    border-radius: 50px;
    display: flex;
    // align-items: baseline;
    justify-content: center;
    color: white;
    margin: 0.225em 0.45em;
    padding: 5px 10px;
    .circle {
      height: 6px;
      width: 6px;
      background-color: white;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  &.iw-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    border-radius: 5px;
  }
  .iw-heading {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    font-size: 10px;
    padding: 5px 5px 0px 5px;
    color: $font-color;
    background-color: $header-background-color;
    & > div {
      text-emphasis: auto-flow;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .el-link--inner {
      color: $font-color;
      white-space: pre;
      margin-left: -30px;
    }
    .left-container {
      margin-left: auto;
      .er-button {
        padding: 3px 5px;
      }
      .btn-pond-edit {
        width: 30px;
        span {
          display: inline-flex;
        }
        .btn-pond-edit__icon {
          img {
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }
  .iw-body {
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    .heading {
      color: $font-color;
    }
    .iw-body-col {
      text-align: center;
    }
  }
  .doc {
    .heading {
      margin-right: 3px;
    }
  }
  .temp {
    display: inline-flex;
    .value {
      line-height: 0.5;
    }
  }
  .heading,
  .value {
    line-height: 1;
  }
  .heading {
    font-weight: 600;
  }
  .iw-header-row {
    .value {
      .pond-size {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .value {
    font-weight: 300;
    display: inline-flex;
    align-items: center;
    &[data-units]:after {
      content: attr(data-units);
      margin-left: 3px;
    }
  }
  div[class^="iw-body-row"] {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 13px;
    margin-bottom: 5px;
    &:not(:first-of-type) {
      border-top: 1px solid #f1f1f1;
      padding-top: 6px;
    }
    &:first-of-type {
      padding-top: 5px;
    }
    &:last-of-type {
      padding-bottom: 5px;
    }
    span[class*="avg-wt-gain-week"]:last-child {
      font-weight: bold;
    }

    span[class*="avg_pos_wt_gain"] {
      color: green;
    }
    span[class*="avg_neg_wt_gain"] {
      color: red;
    }
  }
}
</style>
