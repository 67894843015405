<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: feeding.vue
Description:This file  contains the combination of  pmFeedingTab,pondFeeding,manualFeeding,feedInfoTab
-->
<template>
  <el-container direction="vertical" class="feeding-view">
    <!-- tabs portal -->
    <portal to="destination">
      <div class="portal-container">
        <er-top-route-nav :items="fitlerTabItems"></er-top-route-nav>
      </div>
    </portal>
    <!-- navigation component -->
    <component
      :is="computedComponentName"
      :key="getCurrUserLocationId"
      :tabData="fitlerTabItems[computedComponentName]"
    ></component>
  </el-container>
</template>

<script>
import { mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import redirectsMixin from "@/mixins/redirectsMixin";
import pmFeedingTab from "@/components/pmSchedules/pmFeedingTab";
// components
import manualFeeding from "@/views/culture/manualFeeding.vue";
import pondFeeding from "@/views/culture/pondFeeding.vue";
import feedInfoTab from "@/components/feed/feedTable.vue";
import {
  isValidTabName,
  permissionsToStringMap as permissions,
  getFirstTabBasedOnPermissions
} from "@/middleware/pageAccessManager";
import {
  handleStoreRegistration,
  registerObjOfStoreModules,
  unregisterObjOfStoreModules
} from "@/utils/storeRegisterHandlerUtils";
import urlParamsProvideMixin from "@/mixins/urlParamsProvideMixin";
import store from "@/store/index";
const tabMetaData = {
  pmFeedingTab: {
    name: "pmFeedingTab",
    keyToValidateAccess: "PERMISSIONS",
    // PERMISSIONS: [
    //   permissions.MANAGE_CULTURE,
    //   permissions.MANAGE_SCHEDULES,
    //   permissions.MANAGE_EDIT_SCHEDULES
    // ],
    PERMISSIONS: {
      VIEW: [permissions.MANAGE_CULTURE, permissions.MANAGE_SCHEDULES, permissions.MANAGE_EDIT_SCHEDULES],
      UPDATE: [permissions.MANAGE_SCHEDULES, permissions.MANAGE_EDIT_SCHEDULES],
      DELETE: [permissions.MANAGE_SCHEDULES]
    },
    queryParams: ["pond"]
  },
  pondFeeding: {
    name: "pondFeeding",
    keyToValidateAccess: "PERMISSIONS",
    // PERMISSIONS: [
    //   permissions.MANAGE_CULTURE,
    //   permissions.MANAGE_SCHEDULES,
    //   permissions.MANAGE_EDIT_SCHEDULES
    // ]
    PERMISSIONS: {
      VIEW: [permissions.MANAGE_CULTURE, permissions.MANAGE_SCHEDULES, permissions.MANAGE_EDIT_SCHEDULES],
      UPDATE: [permissions.MANAGE_SCHEDULES, permissions.MANAGE_EDIT_SCHEDULES]
    },
  },
  manualFeeding: {
    name: "manualFeeding",
    keyToValidateAccess: "PERMISSIONS",
    // PERMISSIONS: [permissions.MANAGE_CULTURE, permissions.MANAGE_SCHEDULES]
    PERMISSIONS: {
      VIEW: [permissions.MANAGE_CULTURE, permissions.MANAGE_SCHEDULES, permissions.MANAGE_DEVICES],
      UPDATE: [permissions.MANAGE_SCHEDULES, permissions.MANAGE_DEVICES]
    },
  },
  feedInfoTab: {
    name: "feedInfoTab",
    keyToValidateAccess: "PERMISSIONS",
    // PERMISSIONS: [permissions.MANAGE_CULTURE, permissions.MANAGE_SCHEDULES]
    PERMISSIONS: {
      VIEW: [permissions.MANAGE_CULTURE, permissions.MANAGE_SCHEDULES]
    },
  }
};

export default {
  mixins: [errorHandlerMixin, urlParamsProvideMixin, redirectsMixin],
  components: {
    pmFeedingTab,
    manualFeeding,
    pondFeeding,
    feedInfoTab
  },
  data: function() {
    return {};
  },
  beforeRouteEnter(to, from, next) {
    // registering default modules
    handleStoreRegistration(to, from);
    const childModules = to.meta.childStoreModules;
    const viewPermissions = store.getters["user/getViewPermissions"];
    const userType = store.getters["user/getCurrUserType"];
    let tabName = to.query.tab;
    if (!isValidTabName(tabName, tabMetaData, viewPermissions, userType)) {
      tabName = getFirstTabBasedOnPermissions(
        tabMetaData,
        viewPermissions,
        userType
      );
      next(component => {
        Object.assign(component.uppm__urlParams, { tab: tabName });
        component.rdm__navigateToPathWithLocationId({
          path: "/user/feeding",
          query: {
            tab: tabName
          }
        });
      });
    }
    registerObjOfStoreModules({ [tabName]: childModules[tabName] });
    next();
  },
  beforeRouteUpdate(to, from, next) {
    const PREV_TAB = from.query.tab;
    const ACTIVE_TAB = to.query.tab;
    const childModules = to.meta.childStoreModules;
    const viewPermissions = store.getters["user/getViewPermissions"];
    const userType = store.getters["user/getCurrUserType"];
    let tabName = to.query.tab;
    if (!isValidTabName(tabName, tabMetaData, viewPermissions, userType)) {
      tabName = getFirstTabBasedOnPermissions(
        tabMetaData,
        viewPermissions,
        userType
      );
      Object.assign(this.uppm__urlParams, { tab: tabName });
      this.rdm__navigateToPathWithLocationId({
        path: "/user/feeding",
        query: {
          tab: tabName
        }
      });

      next();
      return;
    }
    if (PREV_TAB === ACTIVE_TAB) {
      next();
      return;
    }
    if (PREV_TAB === undefined) {
      next();
      return;
    }
    const prevTabQueryParams = tabMetaData[PREV_TAB].queryParams;
    if (prevTabQueryParams) {
      prevTabQueryParams.forEach(param => {
        this.uppm__deleteUrlParams(param);
      });
    }
    unregisterObjOfStoreModules({ [PREV_TAB]: childModules[PREV_TAB] });
    registerObjOfStoreModules({ [ACTIVE_TAB]: childModules[ACTIVE_TAB] });
    this.uppm__setUrlParams("tab", ACTIVE_TAB);
    next();
  },
  computed: {
    ...mapGetters("user", {
      getCurrUserType: "getCurrUserType",
      location: "getCurrUserLocation"
    }),
    getCurrUserLocationId() {
      return this.location._id;
    },
    computedComponentName: function() {
      const tabName = this.$route.query.tab;
      if (this.tabComponents[tabName]) {
        return tabName;
      }
      const viewPermissions = store.getters["user/getViewPermissions"];
      const userType = store.getters["user/getCurrUserType"];
      return getFirstTabBasedOnPermissions(
        tabMetaData,
        viewPermissions,
        userType
      );
    },
    getDayTimeInSecs() {
      return this.$commonUtils.DAY_TIME_IN_MS;
    },
    tabComponents() {
      return {
        pmFeedingTab: {
          label: this.$t("Comn_pond_mother"),
          ...tabMetaData.pmFeedingTab
        },
        pondFeeding: {
          label: this.$t("PM_pond_feeding"),
          ...tabMetaData.pondFeeding
        },
        manualFeeding: {
          label: this.$t("Comn_manual_feeding"),
          ...tabMetaData.manualFeeding
        },
        feedInfoTab: {
          label: this.$t("Comn_monitor"),
          ...tabMetaData.feedInfoTab
        }
      };
    },
    fitlerTabItems: function() {
      return this.$gblUAMFilterItemsUserCanView(this.tabComponents);
    }
  }
};
</script>

<style  scoped lang="scss">
.feeding-view {
  background-color: $pondlogs-background-color;
  position: relative;
  height: 100%;
  padding: 10px;
  color: #0a2463;
}
.radio-container {
  .is-checked .el-checkbox__label {
    color: #0A2463 !important;
}
.el-checkbox__label {
   color: #0A2463 !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner{
    background-color: #0A2463 !important;
    border-color: #0A2463 !important;
}
}
</style>
