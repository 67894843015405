<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondListItem.vue
Description: This file contains UI components that show individual pond shown in the left panel in PondMother Schedules page
-->
<template>
  <el-card shadow="never" @click.native="handleItemClick">
    <el-col>{{pondId}}</el-col>
    <el-col>{{pondName}}</el-col>
  </el-card>
</template>

<script>
export default {
  props: {
    pondName: {
      default: "NO_TITLE"
    },
    uniqueIdKey: {
      required: true,
      default: "" + Math.random()
    },
    pondObject: {
      required: true,
      default: () => ({})
    }
  },
  computed: {
    pondId: function () {
      return (this.pondObject || { [this.uniqueIdKey]: "NO_ID" })[
        this.uniqueIdKey
      ];
    }
  },
  methods: {
    handleItemClick () {
      this.$emit("clicked", this.pondId);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
