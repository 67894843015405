<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pmBasicModeCollapseItem.vue
Description: This file contains UI components used to display collapsable pond in basic mode in Feeding PondMother page - PondMother Level
-->
<template>
  <el-row v-loading="loading" element-loading-background="white">
    <el-row>
      <el-row type="flex" justify="center">
        <el-alert
          :title="$t('Comn_alerts')"
          type="error"
          @close="ehm__clearErrorMessages"
          v-if="ehm__unhandledErrorMessage != ''"
          >{{ ehm__unhandledErrorMessage }}</el-alert
        >
      </el-row>
    </el-row>
    <el-row>
      <el-col :span="16" :md="15" :lg="18" :xl="13">
        <el-form class="mode-collapse-item">
          <el-table
            :data="tableData"
            size="mini"
            :indent="10"
            class="card-row-table"
            header-row-class-name="card-row-table__header-row"
            :row-class-name="functionPmTSTableRowClassName"
          >
            <template slot="empty">
              <table-empty-state></table-empty-state>
            </template>
            <el-table-column
              :width="tableColumns.total_feed_kgs.width"
              :label="tableColumns.total_feed_kgs.label"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  size="mini"
                  v-model="scope.row.feed"
                  :min="0"
                  title
                  :precision="2"
                  :disabled="disableTotalFeed(scope.row)"
                  :controls="false"
                  @change="
                    handleChangeInput(
                      $event,
                      scope.$index,
                      scope.row,
                      'total_feed_kgs'
                    )
                  "
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              :width="tableColumns.ocf_g.width"
              :label="tableColumns.ocf_g.label"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  size="mini"
                  v-model="scope.row.ocf"
                  :min="100"
                  :step="50"
                  title
                  :precision="0"
                  :disabled="disableOCF(scope.row)"
                  :controls="false"
                  @change="
                    handleChangeInput($event, scope.$index, scope.row, 'ocf_g')
                  "
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              :width="tableColumns.dispensed_feed.width"
              :label="tableColumns.dispensed_feed.label"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  size="mini"
                  :value="scope.row.dispensed_feed"
                  :precision="0"
                  :disabled="true"
                  :controls="false"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              :width="tableColumns.actions.width"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <!-- schedule controls -->
                <el-row type="flex" align="middle">
                  <template v-if="!hideStatusActionBtns(scope.row)">
                    <er-button
                      v-if="scope.row.ui_status === PM_TS_STATUS.RUNNING"
                      class="btn-actions"
                      btnType="pause"
                      :showIcon="true"
                      size="mini"
                      :disabled="isReadOnly"
                      @click="
                        handleStatusChange(
                          $event,
                          scope.$index,
                          scope.row,
                          PM_TS_STATUS.PAUSED
                        )
                      "
                    ></er-button>
                    <er-button
                      v-if="scope.row.ui_status === PM_TS_STATUS.PAUSED"
                      class="btn-actions"
                      size="mini"
                      btnType="play"
                      :showIcon="true"
                      :disabled="isReadOnly"
                      @click="
                        handleStatusChange(
                          $event,
                          scope.$index,
                          scope.row,
                          PM_TS_STATUS.RUNNING
                        )
                      "
                    ></er-button>
                    <er-button
                      v-if="
                        [PM_TS_STATUS.RUNNING, PM_TS_STATUS.PAUSED].indexOf(
                          scope.row.ui_status
                        ) > -1
                      "
                      size="mini"
                      btnType="stop"
                      :showIcon="true"
                      class="btn-actions"
                      :disabled="isReadOnly"
                      @click="
                        handleStatusChange(
                          $event,
                          scope.$index,
                          scope.row,
                          PM_TS_STATUS.STOPPED
                        )
                      "
                    ></er-button>
                  </template>
                </el-row>
              </template>
            </el-table-column>
          </el-table>
          <el-row
            type="flex"
            class="save-btn-container"
            v-if="!hidePMComponentActionBtns(pmId)"
          >
            <er-button
              size="mini"
              btnType="save"
              :showLabel="true"
              :showIcon="true"
              :disabled="isReadOnly"
              @click="handleSaveScheduleClick"
            ></er-button>
          </el-row>
        </el-form>
      </el-col>
      <el-col class="add-btn-container" :span="8" :md="9" :lg="6" :xl="11">
        <el-row
          type="flex"
          justify="end"
          v-if="!hidePMComponentActionBtns(pmId)"
        >
          <er-button
            size="mini"
            btnType="add"
            icon="el-icon-circle-plus-outline"
            :disabled="disabledAddScheds()"
            :showLabel="true"
            @click="handleAddTSToPm"
          ></er-button>
          <!-- <er-button
            size="mini"
            type="danger"
            @click="handlePMClearFutureSchedules($event, pmId)"
            :disabled="isReadOnly"
            >{{ $t("PM_clr_future_sch") }}</er-button
          > -->
        </el-row>
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import pmSchedulesMixin from "@/mixins/pmSchedulesMixin";
// import PondTS from "@/model/pondTS";
import { mapGetters, mapActions, mapMutations } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { PM_TS_STATUS, PM_MODES } from "@/constants/schedule";

export default {
  mixins: [errorHandlerMixin, pmSchedulesMixin],
  props: ["pm", "pondId"],
  data: function() {
    return {
      pastPickedTime: [],
      timePickerOptions: {
        format: "HH:mm"
      },
      loading: false,
      PM_TS_STATUS
    };
  },
  computed: {
    ...mapGetters("pondMotherSchedules", {
      getPmTSTableData: "getPmTSTableData",
      getDateRange: "getDateRange",
      getPondTSTableData: "getPondTSTableData",
      getSelectedDay: "getSelectedDay"
    }),
    pmId: function() {
      return this.pm._id;
    },
    isExpanded() {
      return this.getExpandedPmKeysPondId[this.pondId].indexOf(this.pmId) > -1;
    },
    selectedDay: function() {
      return this.getSelectedDay;
    },
    tableDataObj: function() {
      return this.getPmTSTableData[
        this.selectedDay + "_" + this.pondId + "_" + this.pmId
      ];
    },
    tableData: function() {
      return this.$lodash.cloneDeep(Object.values(this.tableDataObj || {}))
      // .filter(val => val.running_mode === undefined || val.running_mode !== 'HYBRID');
    },
    canAddASchedule: function() {
      return scope => {
        const tableDataLength = this.tableData.length;
        return (
          tableDataLength < 8 &&
          (tableDataLength === 1 || scope.$index === tableDataLength - 1)
        );
      };
    },
    dateRange: function() {
      return this.getDateRange;
    },
    pm_id: function() {
      return this.pm._id;
    },
    dateRange0: function() {
      return this.dateRange[0];
    },
    dateRange1: function() {
      return this.dateRange[1];
    },
    pondSchedule() {
      return this.getPondTSTableData[this.pondScheduleKey];
    },
    pondScheduleKey() {
      return this.selectedDay + "_" + this.pondId;
    },
    pmMode() {
      return this.pm.managed_by;
    }
  },
  methods: {
    ...mapActions("pondMotherSchedules", {
      addPmTSToPmTSTableAndPondTSTable: "addPmTSToPmTSTableAndPondTSTable",
      addTSToPondTSTable: "addTSToPondTSTable",
      deletePmTSFromPondTS: "deletePmTSFromPondTS",
      updatePmTSTime: "updatePmTSTime",
      createSchedule: "createPondSchedule",
      updatePmTSFeedConfigParams: "updatePmTSFeedConfigParams",
      savePondMotherLevelSchedule: "savePondMotherLevelSchedules"
    }),
    ...mapMutations("pondMotherSchedules", {
      CHECK_VALID_PM_DATA_TO_SAVE: "CHECK_VALID_BASIC_MODE_PM_DATA_TO_SAVE"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    disableFromTime(pm) {
      const casesToDisable = [!pm.enabled.from_time];
      if (casesToDisable.includes(true)) {
        return true;
      } else {
        return false;
      }
    },

    commonDisableCasesForAllFields: function(ts, field_name) {
      const casesToDisable = [
        !ts.enabled[field_name],
        [
          PM_TS_STATUS.COMPLETED,
          PM_TS_STATUS.STOPPED,
          PM_TS_STATUS.UI_COMPLETED
        ].indexOf(ts.ui_status) > -1
      ];
      return casesToDisable.includes(true);
    },
    disableToTime(pmTS) {
      const casesToDisable = [
        this.commonDisableCasesForAllFields(pmTS, "to_time")
      ];
      if (casesToDisable.includes(true)) {
        return true;
      } else {
        return false;
      }
    },
    disableTotalFeed(pmTS) {
      const casesToDisable = [
        this.commonDisableCasesForAllFields(pmTS, "feed")
      ];
      if (casesToDisable.includes(true)) {
        return true;
      } else {
        return false;
      }
    },
    disableFeedGap(pmTS) {
      const casesToDisable = [
        this.commonDisableCasesForAllFields(pmTS, "feed_gap")
      ];
      if (casesToDisable.includes(true)) {
        return true;
      } else {
        return false;
      }
    },
    disableOCF(pmTS) {
      const casesToDisable = [this.commonDisableCasesForAllFields(pmTS, "ocf")];
      if (casesToDisable.includes(true)) {
        return true;
      } else {
        return false;
      }
    },
    disabledAddScheds() {
      const tableLengthWithOutCmpltdTS = this.tableData
        .filter(x => ![PM_TS_STATUS.COMPLETED].includes(x.ui_status))
        .filter(x => !(x.ui_status === "STOPPED" && x.SAVED_AT_DEVICE)).length;
      if (tableLengthWithOutCmpltdTS >= 1) return true;
      if (this.isReadOnly) return true;
      return false;
    },
    handleAddTSToPm() {
      // this.canAddASchedule++;
      try {
        this.$gblUAMCanUserEdit(this.permissions);
        const presentPmTS = this.tableData[this.tableData.length - 1];
        // adding the ts to the pm day timeslots
        if (presentPmTS.e_time_secs <= 86100) {
          this.addPmTSToPmTSTableAndPondTSTable({
            selectedDay: this.selectedDay,
            pmId: this.pmId,
            siblingTSId: presentPmTS.ui_id,
            pondId: this.pondId,
            mode: PM_MODES.BASIC
          });
        }
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    handleDeleteTSFromPm(index, ts) {
      // check whether the pm in has record in the pond schedules
      this.handleDeleteTSFromPond(ts);
      // delete the ts from the pm
      this.tableData.splice(index, 1);
    },
    handleDeleteTSFromPond(ts) {
      // deleting the pm from that timeslot
      this.deletePmTSFromPondTS({
        selectedDay: this.selectedDay,
        pondId: this.pondId,
        pmId: this.pmId,
        pmTSId: ts.ui_id
      });
    },
    handleChangeTPicker(updtPropVal, changedTSObj, propertyName) {
      try {
        const currTSId = changedTSObj.ui_id;
        console.log(updtPropVal);
        this.updatePmTSTime({
          prop: propertyName,
          value: updtPropVal,
          selectedDay: this.selectedDay,
          currTSId,
          pondId: this.pondId,
          pmId: this.pmId
        });
      } catch (err) {
        console.error(err);
      }
    },
    handleChangeInput(updtPropVal, indexInTable, changedTSObj, propertyName) {
      // this.checkStatus();
      // this.isTSValidToUpdate();
      const currTSId = changedTSObj.ui_id;
      this.updatePmTSFeedConfigParams({
        prop: propertyName,
        value: updtPropVal,
        selectedDay: this.selectedDay,
        currTSId,
        pondId: this.pondId,
        pmId: this.pmId,
        userTimeZoneString: this.getUserTimeZoneString
      });
    },
    async handleStatusChange(event, indexInTable, changedTSObj, newStatus) {
      try {
        this.$gblUAMCanUserEdit(this.permissions);
        await this.TSStatusChangeConfirm(newStatus);
        this.loading = true;
        await this.changeStatusOfPmTS({
          tsId: this.tableData[indexInTable].bk_id,
          pondId: this.pondId,
          pmId: this.pmId,
          status: newStatus,
          mode: "MANUAL"
        });
        this.notifyScheduleSuccess("status");
        this.mixPanelEventGenerator({ eventName: "Feeding - PondMother - PondMother Collapse - " + newStatus + " Schedules" });
      } catch (err) {
        if (err !== "cancel") {
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = false;
      }
    },
    async handleSaveScheduleClick() {
      try {
        this.$gblUAMCanUserEdit(this.permissions);
        this.CHECK_VALID_PM_DATA_TO_SAVE({
          selectedDay: this.selectedDay,
          pondId: this.pondId,
          pmId: this.pmId
        });
        await this.checkWhetherToSaveSameSchedForRemainingDays();
        this.loading = true;
        await this.savePondMotherLevelSchedule({
          pmId: this.pmId,
          pondId: this.pondId
        });
        this.notifyScheduleSuccess("saved");
        this.mixPanelEventGenerator({ eventName: "Feeding - PondMother - PondMother Collapse - Save Schedules" });
      } catch (err) {
        this.errorFailToSaveHandler(err);
        // this.notifyScheduleFailed("saved");
      } finally {
        this.loading = false;
      }
    },
    ehm__error422Handler: function(err) {
      const errors = err.response.data.errors;
      let message =
        err.response.data.message || this.$t("Something_went_wrong_try_again");
      if (
        errors &&
        Array.isArray(errors.details) &&
        errors.details.length > 0
      ) {
        message = errors.details[0].message;
      }
      this.ehm__errorFailToSave([{ message }]);
    }
  }
};
</script>

<style lang="scss"></style>
