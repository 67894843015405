<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: mapSideMenuTabs.vue
Description: This file contains UI components that are shown as tabs in maps page right side panel
-->
<template>
  <div class="maps__side-menu-tabs">
    <el-row>
      <el-tabs
        v-model="tabSelected"
        @tab-click="handleTabClick"
        :stretch="true"
      >
        <el-tab-pane name="graphs">
          <span slot="label" class="pond__image__container">
            <img src="@/assets/dashboard/lake.svg" class="pond__image" />
            {{ $tc("Comn_pond", 1) }}
          </span>
        </el-tab-pane>

        <el-tab-pane name="schedules">
          <span slot="label">
            <i class="el-icon-date"></i>
            {{ $t("PM_schedule") }}
          </span>
        </el-tab-pane>

        <el-tab-pane name="alerts">
          <span slot="label">
            <i class="el-icon-bell"></i>
            {{ $t("Comn_alerts") }}
          </span>
        </el-tab-pane>
      </el-tabs>
    </el-row>

    <component
      :is="tabNameToComponent[tabSelected]"
      v-loading="loading"
      element-loading-background="white"
    ></component>
  </div>
</template>

<script>
import mapPondGraphs from "./mapPondGraphs";
import mapPondAlerts from "./mapPondAlerts";
import mapPondSchedules from "./mapPondSchedules";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [errorHandlerMixin],
  components: {
    mapPondGraphs,
    mapPondSchedules,
    mapPondAlerts
  },
  data: function () {
    return {
      tabSelected: "graphs",
      loading: false
    };
  },
  computed: {
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation"
    }),
    ...mapGetters("googleMaps", {
      getSelectedPond: "getSelectedPond"
    }),
    tabNameToComponent: function () {
      return {
        graphs: "mapPondGraphs",
        alerts: "mapPondAlerts",
        schedules: "mapPondSchedules"
      };
    }
  },
  watch: {
    getSelectedPond: async function (newPond, oldPond) {
      await this.handleTabClick({ paneName: this.tabSelected });
    }
  },
  methods: {
    ...mapActions("googleMaps", {
      fetchErrorAlerts: "fetchErrorAlerts",
      fetchPondSchedules: "fetchPondSchedules"
    }),
    handleTabClick: async function (tab) {
      try {
        const tabName = tab.paneName;
        if (tabName === "schedules") {
          this.loading = true;
          await this.fetchPondSchedules();
        } else if (tabName === "alerts") {
          this.loading = true;
          await this.fetchErrorAlerts({
            location_id: this.getCurrUserLocation._id,
            get_all: true
          });
        }
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.maps__side-menu-tabs {
  overflow: hidden;
  height: 100%;
  .el-tabs__nav {
    .el-tabs__active-bar {
      background-color: green;
    }
    .el-tabs__item {
      font-weight: 700;
      color: #233a73;
      @include responsiveProperty(
        font-size,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
    }
    .el-tabs__item.is-active {
      color: green;
      .pond__image {
        filter: invert(39%) sepia(50%) saturate(1667%) hue-rotate(69deg)
          brightness(94%) contrast(93%);
      }
    }
  }

  .pond__image__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pond__image {
    height: 19px;
    padding: 0px 4px 5px 0px;
  }
  .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border-top: unset;
  }
  .el-table__body-wrapper {
    position: relative;
    overflow-y: scroll;
    @include responsiveProperty(height, 380px, 510px, 720px);
  }
}
</style>
