var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('er-data-tables',{key:_vm.$i18n.locale,ref:"table",attrs:{"unique-id":"pond-alerts-table__table","layout":"table","tableData":_vm.alertsTableData,"sortMethodForColumns":_vm.sortMethodForColumns,"columns":_vm.tableColumns,"disable-check":false,"el-table-props":_vm.tableProps,"action-column":false,"type":"white-header-table"},scopedSlots:_vm._u([{key:"device_type",fn:function(ref){
var row = ref.row;
return [_vm._v("\n    "+_vm._s(_vm.$tc(_vm.$constants.deviceTypes[row.data.device_type].lang_key, 1))+"\n  ")]}},{key:"device_id",fn:function(ref){
var row = ref.row;
return [_c('span',{attrs:{"title":row.data.device_code}},[[_vm._v("\n        "+_vm._s(_vm.upm__getValue(row.data))+"\n      ")]],2)]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{attrs:{"content":_vm.renderAlertName(row.data.error_code),"placement":"bottom"}},[_c('img',{staticClass:"alert-icon",attrs:{"rel":"prerender","src":_vm.renderAlertIcon(row.data.error_code)}})])]}},{key:"time",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(row.data)))])]}}])},[_c('template',{slot:"empty"},[_c('span',[_c('img',{staticClass:"no-data__img",attrs:{"src":require("@/assets/no_data.svg")}})]),_vm._v(" "),_c('span',{staticClass:"no-data__text"},[_c('h4',[_vm._v(_vm._s(_vm.$t("No_alerts")))])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }