<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: mapPgGraph.vue
Description: This file is the chart component that displays water quality parameters sent by the pondguard (such as DO & Temp) in the maps page. There 3 yaxis which represents values of DO, Temperature, pH respectively, where as x axis the time.
-->
<template>
  <el-row
    v-loading="loading"
    element-loading-background="white"
    class="map-pg-status-graph"
    ref="pond-guard"
  >
    <layout-toolbar class="pg-graph-filter" gap="5">
      <b class="map__pg__header">{{ $t("Comn_pond_guard") }}</b>
      <div class="filler"></div>
      <er-select
        :key="pondId"
        class="select-pond-guard"
        v-model="selectPG"
        value-key="device_id"
        @change="handleChangeInPg"
        size="mini"
        :placeholder="$t('Ponds_select_pond_quards')"
      >
        <el-option
          v-for="pg in listPondGuards"
          :key="pg.device_code"
          :value="pg"
          :label="pg.device_code"
        ></el-option>
      </er-select>
      <er-select
        class="select-graph-data-type"
        :value="dataViewOption"
        @change="handleCommand"
        size="mini"
      >
        <el-option :label="$t('Pond_raw_data')" value="RAW_DATA"></el-option>
        <el-option :label="$t('Comn_avrg_data')" value="AVG_DATA"></el-option>
      </er-select>
      <er-date-picker
        type="datetimerange"
        unlink-panels
        size="mini"
        :timeZoneString="getUserTimeZoneString"
        :format="upm__getFormatDateAndTimeString"
        value-format="yyyy-MM-dd HH:mm"
        popper-class="pg-date-picker"
        v-model="dateRange"
        :default-time="['00:00:00']"
        :start-placeholder="$t('Comm_Start')"
        :end-placeholder="$t('Comn_End')"
        :disableDateMethod="disableDateMethod"
        :availableInterval="availableInterval"
        :arrShortcuts="arrDatePickerShortCuts"
        :clearable="false"
        @change="handleValueChange"
      ></er-date-picker>
    </layout-toolbar>
    <el-row :key="$i18n.locale">
      <high-charts
        ref="highcharts"
        :options="chartOptions"
        constructor-type="stockChart"
      ></high-charts>
    </el-row>
  </el-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import mapChartMixin from "@/mixins/mapChartMixin";
import { pgStatusGraphConfig } from "./chartOptions";
import { CHART_DATE_FORMATS } from "@/utils/commonUtils";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
export default {
  mixins: [
    errorHandlerMixin,
    mapChartMixin,
    datesHandlerMixin,
    userPreferenceMixin
  ],
  data: function() {
    return {
      sensor_hourly_logs: [],
      dataViewOption: "RAW_DATA",
      dateRange: [],
      listPondGuards: [],
      selectPG: undefined,
      loading: false,
      params: {
        from_date: "",
        to_date: "",
        month_of: null,
        week_of: null
      },
      chartOptions: pgStatusGraphConfig,
      yAxisTextKey: []
    };
  },
  computed: {
    ...mapGetters("googleMaps", {
      getArrSelectedPondPGs: "getArrSelectedPondPGs"
    }),
    ...mapGetters("user", {
      getUserId: "getUserId"
    }),
    criticalLowerLimit() {
      if (!this.selectPG) return 1.5;
      if (!this.selectPG.do_alerts_config) return 1.5;
      if (this.selectPG.do_alerts_config.length === 0) return 1.5;
      return this.selectPG.do_alerts_config[0].critical_lower_limit || 1.5;
    },
    lowerLimit() {
      if (!this.selectPG) return 2.5;
      if (!this.selectPG.do_alerts_config) return 2.5;
      if (this.selectPG.do_alerts_config.length === 0) return 2.5;
      return this.selectPG.do_alerts_config[0].lower_limit || 2.5;
    },
    setPondGuards() {
      return new Set(...this.listPondGuards.map(currPg => currPg.pgId));
    },
    arrDatePickerShortCuts: function() {
      return [
        this.dhm__dateUtilsLib.getDatePickerShortcut(30, "minutes"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(1, "hours"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(3, "hours"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(6, "hours"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(12, "hours"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(24, "hours"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(7, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(15, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(30, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(3, "months"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(6, "months")
      ];
    },
    defaultDateObjDtRangeForCurrCulture() {
      return this.dhm__dateUtilsLib.getDateRangeFromRefDate({
        referenceDate: this.dhm__dateUtilsLib.getCurrDateInGivenTZ(
          this.getUserTimeZoneString
        ),
        distanceFromRefDate: [{ action: "subtract", params: [{ hours: 6 }] }],
        timeZone: this.getUserTimeZoneString,
        actionsOnReferenceDate: [],
        actionsOnDateRangeItem: []
      });
    },
    dateFormat() {
      return "yyyy-MM-dd HH:mm";
    }
  },
  mounted() {
    this.registerResizeObserver("pond-guard");
    this.upm__setDataKeys("code", "title");
  },
  beforeDestroy() {
    this.unRegisterResizeObserver("pond-guard");
  },
  methods: {
    ...mapActions("googleMaps", {
      fetchPondGuardData: "fetchPondGuardData",
      fetchPondPGsInCurrCulture: "fetchPondPGsInCurrCulture",
      fetchAquaLabPondGuardData: "fetchAquaLabPondGuardData"
    }),
    getFormat(date) {
      return this.dhm__dateUtilsLib.formatDate(date, this.dateFormat);
    },
    getShortCutObj(no, unit, customText = undefined) {
      return {
        no,
        unit,
        text: customText || `${this.$t("Comn_last")} ${no} ${this.$tc(unit, 2)}`
      };
    },
    initDateRangeWithMomentObj(dateObjArr) {
      const dates = dateObjArr.map(x => {
        return this.dhm__formatOnDateObjWithLocale(x, this.dateFormat);
      });
      this.$set(this.dateRange, 0, dates[0]);
      this.$set(this.dateRange, 1, dates[1]);
      this.handleValueChange(this.dateRange);
    },
    async initComponent() {
      this.loading = true;
      try {
        await this.fetchPondPGsInCurrCulture({
          pondId: this.pondId,
          params: {
            from_date: this.params.from_date,
            to_date: this.params.to_date
          }
        });
        await this.manageApiCallByUserId();
        this.handleCommand(this.dataViewOption);
      } catch (err) {
        this.ehm__errorMessages(err, true);
        this.listPondGuards = [];
        this.selectPG = undefined;
        this.sensor_hourly_logs = [];
      } finally {
        this.loading = false;
      }
    },
    getAquaLabUsersDevices() {
      const devicesToPond = {
        "5f7cf6bdbf214921efcb520b": [
          {
            device_id: "70B3D5499825AD39",
            device_code: "70B3D5499825AD39",
            manufacture_by: "AQUALAB"
          }
        ],
        "5f7cf6bdbf214921efcb520a": [
          {
            device_id: "70B3D54994B98CFC",
            device_code: "70B3D5499825AD39",
            manufacture_by: "AQUALAB"
          }
        ]
      };
      if (this.getUserId === "5f6a182c0fa44b545871ecb7") {
        return devicesToPond[this.pondId];
      }
      return undefined;
    },
    async manageApiCallByUserId() {
      let pond_guards = this.getArrSelectedPondPGs || [];
      pond_guards = pond_guards.map(x => {
        x.manufacture_by = "ERUVAKA";
        return x;
      });
      const aquaLabPgs = this.getAquaLabUsersDevices();
      if (aquaLabPgs) {
        pond_guards = [...pond_guards, ...aquaLabPgs];
      }
      if (pond_guards.length > 0) {
        this.listPondGuards = pond_guards;
        if (!this.selectPG) {
          this.selectPG = this.listPondGuards[0];
        } else {
          const isExistingInNewList = this.setPondGuards.has(
            this.selectPG.pgId
          );
          if (!isExistingInNewList) {
            this.selectPG = this.listPondGuards[0];
          }
        }
        await this.handleFetchPGData(this.selectPG);
        this.initAxisTextKeys("Comn_date_and_time", [
          "Comn_do_ph",
          "",
          "Comn_temperature_c"
        ]);
        this.initChartLang();
      } else {
        this.listPondGuards = [];
        this.selectPG = undefined;
        this.sensor_hourly_logs = [];
      }
    },
    handleFetchPGData: async function(pg) {
      try {
        let response;
        if (pg.manufacture_by === "AQUALAB") {
          response = await this.fetchAquaLabPondGuardData({
            pgId: pg.device_id,
            params: this.params
          });
        } else {
          response = await this.fetchPondGuardData({
            pondId: this.pondId,
            pgId: pg.device_id,
            params: this.params
          });
        }
        this.sensor_hourly_logs = response.pond_guard_data;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    pondGuardHourly(pondGuardDataDaysWise) {
      const dateHourlyO2 = {};
      const dateHourlyPH = {};
      const dateHourlyTemp = {};
      const milliSecs = date => date.getTime();
      const getDate = dateStr => this.dhm__dateUtilsLib.parseISO(dateStr);
      const roundOff = value => (value ? +value.toFixed(2).toString() : value);
      pondGuardDataDaysWise.forEach(dayData => {
        const timeSlotData = dayData.data;
        // the date from the server side is UTC with time equal to the user timezone
        const date = getDate(dayData.date);
        const dateTimeFeed = milliSecs(date);
        [dateHourlyO2, dateHourlyPH, dateHourlyTemp].forEach(x => {
          if (!x[dateTimeFeed]) {
            x[dateTimeFeed] = 0;
          }
        });
        dateHourlyO2[dateTimeFeed] = +roundOff(timeSlotData.dissolved_oxygen);
        dateHourlyPH[dateTimeFeed] = +roundOff(timeSlotData.ph);
        dateHourlyTemp[dateTimeFeed] = +roundOff(timeSlotData.temperature);
      });
      let hourlyO2 = [];
      const timeFormat = key => {
        const format =
          this.dataViewOption === "RAW_DATA"
            ? CHART_DATE_FORMATS.dmyt
            : CHART_DATE_FORMATS.t;
        return format;
      };
      Object.keys(dateHourlyO2).forEach(key => {
        const o2 = Number(dateHourlyO2[key]);
        if (o2 > 0) {
          hourlyO2.push({
            x: Number(key),
            y: o2,
            timeFormat: timeFormat(key)
          });
        }
      });
      let hourlyPH = [];
      Object.keys(dateHourlyPH).forEach(key => {
        hourlyPH.push({
          x: Number(key),
          y: Number(dateHourlyPH[key]),
          timeFormat: timeFormat(key)
        });
      });
      let hourlyTemp = [];
      Object.keys(dateHourlyTemp).forEach(key => {
        const temp = Number(dateHourlyTemp[key]);
        if (temp > 0) {
          hourlyTemp.push({
            x: Number(key),
            y: temp,
            timeFormat: timeFormat(key)
          });
        }
      });
      hourlyO2 = hourlyO2.sort((a, b) => a.x - b.x);
      hourlyPH = hourlyPH.sort((a, b) => a.x - b.x);
      hourlyTemp = hourlyTemp.sort((a, b) => a.x - b.x);
      return { hourlyO2, hourlyPH, hourlyTemp };
    },
    pondGuardHourlyAvgData: function(pondGuardDataDaysWise, tempDateRange) {
      const groupByHourlyTemp = {};
      const groupByHourlyO2 = {};
      const groupByHourlyPH = {};
      const dateRangeDiff = {};
      const roundOff = value => +value.toFixed(2).toString();
      const milliSecs = date => date.getTime();
      const getDate = dateStr => this.dhm__dateUtilsLib.parseISO(dateStr);
      const difference = this.dhm__dateUtilsLib.differenceInHours(
        tempDateRange[1],
        getDate(tempDateRange[0])
      );
      const timeFormat = key => {
        const format =
          this.dataViewOption === "RAW_DATA"
            ? CHART_DATE_FORMATS.dmyt
            : CHART_DATE_FORMATS.t;
        return format;
      };
      pondGuardDataDaysWise.forEach(dayData => {
        const timeSlotData = dayData.data;
        const hours = Number(timeSlotData.time.substr(0, 2));
        let dateTimeFeed;
        if (difference < 23) {
          dateTimeFeed = this.dhm__dateUtilsLib.startOfHour(timeSlotData.date);
        } else {
          dateTimeFeed = this.dhm__dateUtilsLib.add(
            this.dhm__dateUtilsLib.startOfDay(tempDateRange[0]),
            {
              hours
            }
          );
        }
        dateTimeFeed = milliSecs(
          this.dhm__dateUtilsLib.castBrowserDateToUserUTC(dateTimeFeed)
        );
        [
          groupByHourlyTemp,
          groupByHourlyO2,
          groupByHourlyPH,
          dateRangeDiff
        ].forEach(x => {
          if (!x[dateTimeFeed]) {
            x[dateTimeFeed] = 0;
          }
        });
        groupByHourlyTemp[dateTimeFeed] += timeSlotData.temperature;
        groupByHourlyO2[dateTimeFeed] += timeSlotData.dissolved_oxygen;
        groupByHourlyPH[dateTimeFeed] += timeSlotData.ph;
        dateRangeDiff[dateTimeFeed]++;
      });
      Object.keys(groupByHourlyTemp).forEach(key => {
        groupByHourlyTemp[key] = +roundOff(
          groupByHourlyTemp[key] / dateRangeDiff[key]
        );
        groupByHourlyO2[key] = +roundOff(
          groupByHourlyO2[key] / dateRangeDiff[key]
        );
        groupByHourlyPH[key] = +roundOff(
          groupByHourlyPH[key] / dateRangeDiff[key]
        );
      });
      const padDateRanges = Object.keys(groupByHourlyTemp);
      let [
        resultGroupByHourlyTemp,
        resultGroupByHourlyO2,
        resultGroupByHourlyPH
      ] = padDateRanges.reduce(
        (acc, dateKey) => {
          [
            groupByHourlyTemp[dateKey],
            groupByHourlyO2[dateKey],
            groupByHourlyPH[dateKey]
          ].forEach((value, index) => {
            if (value) {
              acc[index].push({
                x: Number(dateKey),
                y: value,
                timeFormat: timeFormat(dateKey)
              });
            } else {
              acc[index].push({
                x: Number(dateKey),
                y: null,
                timeFormat: timeFormat(dateKey)
              });
            }
          });
          return acc;
        },
        [[], [], []]
      );
      resultGroupByHourlyTemp = resultGroupByHourlyTemp.sort(
        (a, b) => a.x - b.x
      );
      resultGroupByHourlyO2 = resultGroupByHourlyO2.sort((a, b) => a.x - b.x);
      resultGroupByHourlyPH = resultGroupByHourlyPH.sort((a, b) => a.x - b.x);
      return {
        groupByHourlyTemp: resultGroupByHourlyTemp,
        groupByHourlyO2: resultGroupByHourlyO2,
        groupByHourlyPH: resultGroupByHourlyPH
      };
    },
    handleChangeInPg: async function(pm) {
      this.loading = true;
      await this.handleFetchPGData(pm);
      this.handleCommand(this.dataViewOption);
      this.loading = false;
    },
    handleValueChange: function(currentSelectedValues) {
      // converting YYYY-MM-DD HH:mm strings to utc String
      if (!currentSelectedValues) return;
      let tempValues = currentSelectedValues;
      tempValues = currentSelectedValues.map(x => {
        return this.dhm__dateUtilsLib.castBrowserDateToUserUTC(
          this.dhm__dateUtilsLib.parse(x, this.dateFormat, new Date()),
          this.dhm__getUserTZStringObj
        );
      });
      // init from_date , to_date to the user time zone
      this.params.from_date = tempValues[0].toISOString();
      this.params.to_date = tempValues[1].toISOString();
      this.initComponent();
    },
    initChart() {
      let result = {
        hourlyO2: [],
        hourlyPH: [],
        hourlyTemp: []
      };
      let hourlyO2 = [];
      let hourlyPH = [];
      let hourlyTemp = [];
      // where utc is the time in user time zone
      const tempDateRange = [
        this.dhm__dateUtilsLib.parseISO(this.params.from_date),
        this.dhm__dateUtilsLib.parseISO(this.params.from_date)
      ];
      if (this.sensor_hourly_logs.length > 0) {
        if (this.dataViewOption === "RAW_DATA") {
          result = this.pondGuardHourly(this.sensor_hourly_logs);
          hourlyO2 = result.hourlyO2;
          hourlyPH = result.hourlyPH;
          hourlyTemp = result.hourlyTemp;
        } else if (this.dataViewOption === "AVG_DATA") {
          result = this.pondGuardHourlyAvgData(
            this.sensor_hourly_logs,
            tempDateRange
          );
          hourlyO2 = result.groupByHourlyO2;
          hourlyPH = result.groupByHourlyPH;
          hourlyTemp = result.groupByHourlyTemp;
        }
      }
      this.initChartSeries(hourlyO2, hourlyPH, hourlyTemp);
    },
    initChartSeries(hourlyO2, hourlyPH, hourlyTemp) {
      this.chartOptions.series = [];
      if (hourlyO2.length > 0 || hourlyPH.length > 0 || hourlyTemp.length > 0) {
        const nameObj = series_name => ({
          series_name,
          device_code: this.sensor_hourly_logs[0].pond_guard_code,
          device_code_text: this.$t("Comn_device_id")
        });
        this.chartOptions.series.push({
          name: nameObj(this.$t("PM_dissolved_oxygen_mgl")),
          data: hourlyO2,
          color: "#66CD00",
          type: "spline",
          pointWidth: 10,
          visible: true,
          // TODO: Need to integrate pg critical do and low do values
          zones: [
            {
              className: "critical-do",
              value: this.criticalLowerLimit,
              color: "#FF0000"
            },
            {
              className: "low-do",
              value: this.lowerLimit,
              color: "#ffa500"
            }
          ]
        });
        this.chartOptions.series.push({
          name: nameObj(this.$t("Comn_ph")),
          data: hourlyPH,
          type: "spline",
          yAxis: 1,
          pointWidth: 10,
          color: "#FFA500",
          visible: false
        });
        this.chartOptions.series.push({
          name: nameObj(this.$t("Comn_temperature_c")),
          data: hourlyTemp,
          type: "spline",
          yAxis: 2,
          pointWidth: 10,
          color: "#4572A7",
          visible: true
        });
        this.$nextTick(() => {
          if (!this.$refs.highcharts) return;
          const chart = this.$refs.highcharts.chart;
          const xAxis = chart.xAxis[0];
          xAxis.setExtremes(xAxis.dataMin, xAxis.dataMax);
        });
      }
    },
    setAxisText: function() {
      this.chartOptions.xAxis.title.text = this.$t(this.xAxisTextKey);
      this.chartOptions.yAxis[0].title.text = this.$t(this.yAxisTextKey[0]);
      this.chartOptions.yAxis[1].title.text = this.$t(this.yAxisTextKey[1]);
      this.chartOptions.yAxis[2].title.text = this.$t(this.yAxisTextKey[2]);
    },
    handleCommand: function(command) {
      this.dataViewOption = command;
      this.initChart();
    }
  }
};
</script>
<style lang="scss">
.map-pg-status-graph {
  .map__pg__header {
    padding-left: 10px;
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
  }
  .pg-graph-filter {
    .el-range-editor--mini {
      flex-grow: 5;
      flex-basis: 100%;
    }
    .select-pond-guard {
      flex-grow: 1;
    }
    .select-graph-date-type {
      flex-grow: 1;
    }
    .el-range-editor--mini .el-range-separator {
      width: 6%;
    }
    .el-select {
      .el-input--suffix .el-input__inner {
        padding-right: 20px;
      }
      .el-input__icon {
        width: 15px;
      }
    }
  }
}
</style>
