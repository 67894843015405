<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: mapPondDayFeed.vue
Description: This file is the chart component that displays cumulative feed dispsensed all over the culture for a pond  in the maps page. Here x axis is the feeding date and y axis is the amount of feed dispensed
-->
<template>
  <el-row
    v-loading="loading"
    element-loading-background="white"
    class="map-pond-day-feed"
  >
    <el-col>
      <el-row type="flex" justify="start">
        <el-col class="map__pond__header">
          <b>{{ `${$tc("Comn_pond", 1)} ${$t("Comn_cumu_feed")}` }}</b>
        </el-col>
        <el-col :span="13">
          <!-- <er-date-picker
            v-model="dateRange"
            :clearable="false"
            @change="handleValueChange"
          ></er-date-picker>-->
        </el-col>
      </el-row>
      <el-row :key="$i18n.locale">
        <high-charts
          :options="chartOptions"
          ref="highcharts"
          constructor-type="stockChart"
        ></high-charts>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import mapChartMixin from "@/mixins/mapChartMixin";
import { pondScheduleGraphConfig } from "./chartOptions";

export default {
  mixins: [errorHandlerMixin, mapChartMixin, datesHandlerMixin],
  data: function () {
    return {
      loading: false,
      dateRange: [],
      params: {
        from_date: "",
        to_date: "",
        month_of: null,
        week_of: null
      },
      chartOptions: pondScheduleGraphConfig,
      pondSlotFeedLogs: [],
      manualFeedLogs: []
    };
  },
  computed: {
    ...mapGetters("googleMaps", {
      getPondScheduleGraphSlotWiseFeed: "getPondScheduleGraphSlotWiseFeed",
      getManualFeedData: "getManualFeedData"
    }),
    defaultDateObjDtRangeForCurrCulture() {
      return this.dhm__dateUtilsLib.getDateRangeFromRefDate({
        referenceDate: this.dhm__dateUtilsLib.getCurrDateInGivenTZ(
          this.getUserTimeZoneString
        ),
        distanceFromRefDate: [{ action: "subtract", params: [{ days: 14 }] }],
        timeZone: this.getUserTimeZoneString,
        actionsOnReferenceDate: [],
        actionsOnDateRangeItem: []
      });
    }
  },
  methods: {
    ...mapActions("googleMaps", {
      fetchSlotWiseFeed: "fetchSlotWiseFeed",
      fetchManualFeedData: "fetchManualFeedData"
    }),
    async initComponent(pondId) {
      this.loading = true;
      try {
        await this.fetchSlotWiseFeed({
          deviceType: "pond",
          params: this.params
        });
        await this.fetchManualFeedData({
          deviceType: "pond",
          params: { ...this.params, get_all: true }
        });
        this.pondSlotFeedLogs = this.getPondScheduleGraphSlotWiseFeed;
        this.manualFeedLogs = this.getManualFeedData;
        this.initAxisTextKeys("Comn_date", "Comn_feed_kg");
        this.initChartLang();
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    pondScheduleWiseFeed(pondFeedDaysWise, manualFeedLogs, dateRange) {
      const milliSecs = (date) => date.getTime();
      const dateScheduleWiseFeed = {};
      const dateScheduleWiseDispFeed = {};
      const dateManualFeed = {};
      const dateFeedName = {};
      // const dayStart = this.dhm__dateUtilsLib.parseISO(
      //   dateRange[0] + "T00:00:00.000Z"
      // );
      // const dayEnd = this.dhm__dateUtilsLib.parseISO(
      //   dateRange[1] + "T00:00:00.000Z"
      // );
      // while (dayStart <= dayEnd) {
      //   dateScheduleWiseFeed[milliSecs(dayStart)] = 0;
      //   dateScheduleWiseDispFeed[milliSecs(dayStart)] = 0;
      //   dateManualFeed[milliSecs(dayStart)] = 0;
      //   dateFeedName[milliSecs(dayStart)] = 0;
      //   dayStart = .add(1, "days");
      // }
      pondFeedDaysWise.forEach((dayFeed) => {
        let date = this.dhm__dateUtilsLib.parseISO(dayFeed.date);
        date = milliSecs(date);
        dateScheduleWiseFeed[date] = Number(dayFeed.feed.toFixed(2));
        dateScheduleWiseDispFeed[date] = Number(
          dayFeed.dispensed_feed.toFixed(2)
        );
      });
      manualFeedLogs.forEach((dayFeed) => {
        let date = this.dhm__dateUtilsLib.parseISO(dayFeed.date);
        date = milliSecs(date);
        dateManualFeed[date] = Number(dayFeed.feed_dispensed.toFixed(2));
        dateFeedName[date] = dayFeed.feed_name;
      });
      let scheduleFeed = Object.keys(dateScheduleWiseFeed).map((key) => {
        return {
          x: Number(key),
          y: dateScheduleWiseFeed[key]
        };
      });
      let scheduleDispensedFeed = Object.keys(dateScheduleWiseDispFeed).map(
        (key) => {
          return {
            x: Number(key),
            y: dateScheduleWiseDispFeed[key]
          };
        }
      );
      let manualFeed = Object.keys(dateManualFeed).map((key) => ({
        x: Number(key),
        y: dateManualFeed[key]
      }));
      scheduleFeed = scheduleFeed.sort((a, b) => a.x - b.x);
      scheduleDispensedFeed = scheduleDispensedFeed.sort((a, b) => a.x - b.x);
      manualFeed = manualFeed.sort((a, b) => a.x - b.x);
      return { scheduleFeed, scheduleDispensedFeed, manualFeed };
    },
    initChart() {
      let result = {};
      let feedLimit = [];
      let dispensedLimit = [];
      let manualFeedLimit = [];
      let totalFeedLimit = 0;
      let totalDispensedLimit = 0;
      let totalManualFeed = 0;
      if (
        (this.pondSlotFeedLogs || []).length === 0 &&
        (this.manualFeedLogs || []).length === 0
      ) {
        this.chartOptions.series = [];
        return;
      }
      result = this.pondScheduleWiseFeed(
        this.pondSlotFeedLogs,
        this.manualFeedLogs,
        [this.params.from_date, this.params.to_date]
      );
      feedLimit = result.scheduleFeed;
      dispensedLimit = result.scheduleDispensedFeed;
      manualFeedLimit = result.manualFeed;
      totalFeedLimit = Object.values(feedLimit)
        .map((feed) => feed.y)
        .reduce((acc, curr) => acc + curr, 0);
      totalDispensedLimit = Object.values(dispensedLimit)
        .map((feed) => feed.y)
        .reduce((acc, curr) => acc + curr, 0);
      totalManualFeed = Object.values(manualFeedLimit)
        .map((feed) => feed.y)
        .reduce((acc, curr) => acc + curr, 0);

      this.initChartSeries(
        feedLimit,
        dispensedLimit,
        manualFeedLimit,
        totalFeedLimit,
        totalDispensedLimit,
        totalManualFeed
      );
    },
    initChartSeries(
      feedLimit,
      dispensedLimit,
      manualFeedLimit,
      totalFeedLimit,
      totalDispensedLimit,
      totalManualFeed
    ) {
      this.chartOptions.series = [];
      this.chartOptions.series.push({
        name: {
          legend: `${this.$t("PM_configured")}`,
          tooltip: this.$t("PM_configured")
        },
        data: feedLimit,
        type: "spline",
        color: "#ffa500",
        visible: false
      });
      this.chartOptions.series.push({
        name: {
          legend: `${this.$t("PM_dispensed")}`,
          tooltip: this.$t("PM_dispensed")
        },
        data: dispensedLimit,
        type: "spline",
        color: "#4572A7",
        visible: true
      });
      this.chartOptions.series.push({
        name: {
          legend: `${this.$t("Comn_manual_feed")}`,
          tooltip: this.$t("Comn_manual_feed")
        },
        data: manualFeedLimit,
        type: "spline",
        color: "#66cd00",
        visible: false
      });
    },
    async handleValueChange(currentSelectedValues) {
      this.params.from_date = currentSelectedValues[0];
      this.params.to_date = currentSelectedValues[1];
      await this.initComponent(this.pondId);
    }
  }
};
</script>
<style lang="scss">
.map-pond-day-feed {
  .map__pond__header {
    padding-left: 10px;
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    display: flex;
  }
}
</style>
