<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: mapPondDetailsSideMenu.vue
Description: This file contains UI components shown as the side panel in maps page and contains all the details related to the choosen pond
-->
<template>
  <el-drawer
    :visible.sync="computedVisible"
    direction="rtl"
    custom-class="pond-details-side-menu"
    :modal="false"
    ref="pondDetailsSideMenu"
    :before-close="handleBeforeClose"
    :wrapperClosable="false"
    :show-close="false"
  >
    <el-row type="flex" class="culture-details-row">
      <span :span="16" class="pond__title__header">
        <p class="truncate">{{ getPondTitle }}</p>
        <div class="pond__title__sub">
          <div
            v-upm__units.digitPrecision="getPondSizeAndUnits(currentPond)"
          ></div>
        </div>
      </span>
      <layout-toolbar>
        <span :span="6" class="doc__header">
          <div class="active-tag">
            <span class="circle"></span>
            <p class="small-size">
              DOC:
              <span>
                {{ daysOfCulture }}
                {{ $t("Comn_days_new") }}
              </span>
            </p>
          </div>
        </span>
        <span :span="2" class="close__container">
          <er-button
            class="close__button"
            type="text"
            icon="el-icon-close"
            @click="handleClose"
          ></er-button>
        </span>
      </layout-toolbar>
    </el-row>
    <el-divider class="el-horizontal__line"></el-divider>
    <el-row class="pond-health-row" type="flex" justify="space-between">
      <span class="pond-health-col">
        <span class="title">DO:</span>
        <span
          :title="ftm__formatEmpty(currentPond.do)"
          :data-units="currentPond.do ? 'mg/L' : ''"
          class="value"
        >
          {{ ftm__formatEmpty(ftm__digitPrecision(currentPond.do)) }}
        </span>
      </span>
      <span class="pond-health-col">
        <span class="title">{{ $t("Comn_temp") }}:</span>
        <span
          class="value"
          :title="ftm__formatEmpty(currentPond.temp)"
          :data-units="currentPond.temp ? `(\u00b0C)` : ''"
        >
          {{ ftm__formatEmpty(ftm__digitPrecision(currentPond.temp)) }}
        </span>
      </span>
      <span class="pond-health-col">
        <span class="title"> ABW: </span>
        <span class="value" :data-units="`g`">
          {{ ftm__formatEmpty(ftm__digitPrecision(currentPond.abw)) }}
        </span>
      </span>
      <span class="pond-health-col">
        <span
          class="title"
          :title="
            ftm__formatEmpty(((currentPond.awg || [0])[0] || { awg: 0 }).awg)
          "
        >
          {{ $t("Comn_awg") }}:
        </span>
        <span class="value" :data-units="`g`">
          {{
            ftm__digitPrecision(((currentPond.awg || [0])[0] || { awg: 0 }).awg)
          }}
        </span>
      </span>
    </el-row>
    <el-divider class="el-horizontal__line"></el-divider>

    <template v-if="currentPond.status === 'ACTIVE'">
      <map-side-menu-tabs></map-side-menu-tabs>
    </template>
    <template v-else>
      &nbsp;
      <center>
        <el-tag type="info" effect="dark">{{
          $t("Comn_culture_not_started_msg")
        }}</el-tag>
      </center>
    </template>
  </el-drawer>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import mapSideMenuTabs from "./mapSideMenuTabs";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
// import googleMaps from "@/store/components/googleMaps";
export default {
  mixins: [errorHandlerMixin, filtersMixin],
  components: {
    mapSideMenuTabs
  },
  props: ["visible"],
  data: function() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters("googleMaps", {
      getCurrentCulture: "getCurrentCulture",
      currentPond: "getCurrentClickedPond"
    }),
    ...mapGetters("user", {
      getUserCountryName: "getUserCountryName",
      getPreferredUnits: "getPreferredUnits"
    }),
    daysOfCulture: function() {
      const value = (this.getCurrentCulture || { doc: -1 }).doc;
      return value >= 0 ? value + 1 : "--";
    },
    getPondTitle() {
      return (this.currentPond || { title: "NO TITLE" }).title;
    },
    getPondSizeAndUnits() {
      return pond => {
        const pondAreaAndUnits = {
          value: pond.size || 0,
          previousunits: pond.units,
          units: this.getPreferredUnits.pond_area_units,
          modifiersParams: { digitPrecision: [2] }
        };
        return pondAreaAndUnits;
      };
    },

    computedVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit("close");
      }
    },
    getPondGuardData: function() {
      const arrayPGs = this.currentPond.pond_guards_data || [];
      const length = arrayPGs.length;
      if (length > 0) {
        const totalDO = arrayPGs
          .map(x => x.sensor_values.dissolved_oxygen)
          .reduce((x, y) => x + (y || 0));
        const totalTemperature = arrayPGs
          .map(x => x.sensor_values.temperature)
          .reduce((x, y) => x + (y || 0));
        const totalpH = arrayPGs
          .map(x => x.sensor_values.ph)
          .reduce((x, y) => x + (y || 0));
        return {
          dissolved_oxygen: totalDO / length,
          temperature: totalTemperature / length,
          ph: totalpH / length
        };
      }
      return { dissolved_oxygen: 0, temperature: 0, ph: 0 };
    },
    updatePondSizeBasedOnUnits() {
      return pondSelected => {
        const size = (pondSelected || { size: 0 }).size;
        // const units = (pondSelected || { units: "ha" }).units;
        // const userLocation = this.getUserCountryName.toLowerCase();
        // if (userLocation === "india") {
        //   return this.$commonUtils.castPondAreaSize(size, units, "ac");
        // }
        return this.upm__convertUnits(
          size,
          this.upm__backendUnits.pond_area_units,
          this.getPreferredUnits.pond_area_units
        );
      };
    }
  },
  methods: {
    ...mapActions("header", {
      changeSideNavStatus: "changeSideNavStatus"
    }),
    handleClose: function() {
      this.$refs.pondDetailsSideMenu.closeDrawer();
      this.$emit("close");
    },
    handleBeforeClose: function(done) {
      done(true);
    }
  }
};
</script>
<style lang="scss">
.pond-details-side-menu {
  box-shadow: unset;
  width: 100% !important;
  .culture-details-row,
  .pond-health-row {
    padding-left: 10px;
    padding-right: 10px;
  }
  .culture-details-row {
    padding-top: 5px;
    padding-bottom: 5px;
    // line-height: 3em;
    align-items: center;
    display: flex;
    justify-content: space-between;
    .pond__title__header {
      color: $primary-color;
      font-weight: 600;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @include responsiveProperty(
        font-size,
        $app_font_size_1,
        $app_font_size_2,
        $app_font_size_3
      );
    }
    .pond__title__pond__name {
      color: $primary-color;
      font-weight: 600;
      letter-spacing: 1px;
      display: flex;
      justify-content: flex-start;
      @include responsiveProperty(
        font-size,
        $app_font_size_1,
        $app_font_size_2,
        $app_font_size_3
      );
      // @include responsiveProperty(font-size, 1.1em, 1em, 0.9em);
    }
    .truncate {
      white-space: break-spaces;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
    }
    .pond__title__sub::after {
      content: ")";
    }
    .pond__title__sub::before {
      content: "(";
    }
    .pond__title__sub {
      // line-height: 1.15;
      letter-spacing: initial;
      display: flex;
      align-items: flex-end;

      @include responsiveProperty(
        font-size,
        $app_font_size_1,
        $app_font_size_2,
        $app_font_size_3
      );
      //@include responsiveProperty(font-size, 0.75em, 0.75em, 0.75em);
      .pond__title__area {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 50px;
      }
    }
    .doc__header {
      color: black;

      @include responsiveProperty(
        font-size,
        $app_font_size_1,
        $app_font_size_2,
        $app_font_size_3
      );
      // @include responsiveProperty(font-size, 1.1em, 1em, 0.9em);
    }
    > span {
      display: inline-flex;
    }
    [data-units] {
      &:after {
        content: attr(data-units);
        display: inline;
        margin-left: 3px;
        margin-right: 3px;
      }
    }
    .close__button {
      padding: 4px;
      display: flex;
      @include responsiveProperty(
        font-size,
        $app_font_size_1,
        $app_font_size_2,
        $app_font_size_3
      );
    }
  }
  .pond-health-row {
    padding-top: 17px;
    padding-bottom: 17px;
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    // line-height: 3em;
    // @include responsiveProperty(font-size, 1em, 0.9em, 0.7em);
    display: flex;
    margin: 0;
    color: black;
    .ph__container {
      color: red;
    }
    > span {
      display: inline-flex;
      p {
        min-width: 30px;
        max-width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .pond-health-col {
      .title {
        margin-right: 3px;
      }
    }
    [data-units] {
      &:after {
        content: attr(data-units);
        display: inline;
        margin-left: 3px;
        margin-right: 3px;
      }
    }
  }
  .el-divider {
    margin: 0;
    padding: 0;
  }
  .el-drawer__header {
    padding: 0;
    margin: 0;
  }
  .el-horizontal__line {
    display: block;
    height: 1px;
    width: 100%;
  }
}
</style>
