/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: mapUtils.js
Description: Contains stateless helper functions used in maps
*/
import { zipObject } from "lodash";
/* eslint-disable */
const mapUtils = {
  getFeaturePolygon(inputGeometry, id, properties) {
    let arrLatLngs = [];
    let latLngBound = new google.maps.LatLngBounds();
    inputGeometry.coordinates.forEach(coordinate => {
      /******
       *  order should be lng and lat because GeoJson array will have in that manner
       *  Please refer to GeoJson format
       ******/
      let latLng = zipObject(["lng", "lat"], coordinate);
      latLngBound.extend(latLng);
      arrLatLngs.push(mapUtils.getLatLng(latLng));
    });

    //  let polygon = new google.maps.Polygon(Array(arrLatLngs))
    properties.center = mapUtils.getPolygonCentroid(
      mapUtils.castToMVCArray(arrLatLngs)
    );
    properties["latLgnBounds"] = latLngBound;
    /**
     * Need to find solution for concave paths
     */
    // if(!mapUtils.containsLocation(properties.center,polygon)){
    //     properties.center = latLngBound.getSouthWest()
    // }
    let geometry = new google.maps.Data.Polygon(Array(arrLatLngs));
    let feature = new google.maps.Data.Feature({ geometry, id, properties });
    return feature;
  },
  getPolygonCentroid(pts) {
    let first = pts.getAt(0);
    let last = pts.getAt(pts.getLength() - 1);
    if (first.lat() !== last.lat() || first.lng() !== last.lng())
      pts.push(first);
    let twicearea = 0;
    let x = 0;
    let y = 0;
    let nPts = pts.length;
    let p1;
    let p2;
    let f;
    for (var i = 0, j = nPts - 1; i < nPts; j = i++) {
      p1 = pts.getAt(i);
      p2 = pts.getAt(j);
      f = p1.lat() * p2.lng() - p2.lat() * p1.lng();
      twicearea += f;
      x += (p1.lat() + p2.lat()) * f;
      y += (p1.lng() + p2.lng()) * f;
    }
    f = twicearea * 3;
    return mapUtils.getLatLng({ lat: x / f, lng: y / f });
  },
  castToMVCArray(Dim2Coordinates) {
    return new google.maps.MVCArray(Dim2Coordinates);
  },
  getPolyCoordinates: function(mapData, polygonID) {
    let polyMVCArray = mapUtils.castToMVCArray(
      mapData
        .getFeatureById(polygonID)
        .getGeometry()
        .getArray()[0]
        .getArray()
    );
    return polyMVCArray;
  },
  getPolyCenter(polyCoordinates) {
    var latitudes = [];
    var longitudes = [];
    var vertices = polyCoordinates;
    // put all latitudes and longitudes in arrays
    for (var i = 0; i < vertices.length; i++) {
      longitudes.push(vertices.getAt(i).lng());
      latitudes.push(vertices.getAt(i).lat());
    }
    // sort the arrays low to high
    latitudes.sort();
    longitudes.sort();
    // get the min and max of each
    var lowX = latitudes[0];
    var highX = latitudes[latitudes.length - 1];
    var lowy = longitudes[0];
    var highy = longitudes[latitudes.length - 1];
    // center of the polygon is the starting point plus the midpoint
    var centerX = lowX + (highX - lowX) / 2;
    var centerY = lowy + (highy - lowy) / 2;
    return new google.maps.LatLng(centerX, centerY);
  },

  // Fetching user present location using geolocation api
  captureUserLocation() {
    let geoLocationAPI = navigator.geolocation;
    return new Promise((resolve, reject) => {
      function success(position, callback) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        resolve({
          message: "Capture User location",
          location: new google.maps.LatLng(latitude, longitude)
        });
      }
      function error(positionErr) {
        reject({ message: "Unable to retrieve your location" });
      }
      if (!navigator.geolocation) {
        reject({ message: "Geolocation is not supported by your browser" });
      } else {
        geoLocationAPI.getCurrentPosition(success, error, { timeout: 10000 });
      }
    });
  },
  getArea(poly) {
    var areaInHectares =
      parseFloat(google.maps.geometry.spherical.computeArea(poly.getPath())) *
      0.0001;
    return areaInHectares.toFixed(2);
  },
  containsLocation(latLng, polygon) {
    return google.maps.geometry.poly.containsLocation(latLng, polygon);
  },
  getLatLng(latLngLiteral) {
    return new google.maps.LatLng(latLngLiteral);
  }
};

export default mapUtils;
