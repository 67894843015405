<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: scheduleStatus.vue
Description: This file contains UI components the show schedule status shown in feeding PondMother tab
-->
<template>
  <span>
    <el-tooltip :content="title" placement="top">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.10588 14.3905L2.35294 15.1479C2.16471 15.3372 2.16471 15.5266 2.25882 15.7159C2.35294 15.9053 2.54118 15.9999 2.72941 15.9999H5.83529C6.11765 15.9999 6.4 15.7159 6.4 15.4319V12.3076C6.4 12.1183 6.30588 11.9289 6.11765 11.8342C5.92941 11.7396 5.64706 11.8342 5.55294 11.9289L4.70588 12.781C4.42353 12.5916 4.14118 12.3076 3.85882 12.1183C2.44706 10.6982 1.88235 8.61531 2.25882 6.72182C2.72941 4.73365 4.14118 3.12419 6.02353 2.46146C6.21176 2.36679 6.4 2.17744 6.4 1.98809V0.851996C6.4 0.662647 6.30588 0.567972 6.21176 0.378623C6.02353 0.283949 5.83529 0.283949 5.74118 0.378623C2.35294 1.32537 0 4.44963 0 8.04726C0 10.1301 0.847059 12.2129 2.35294 13.7277C2.63529 13.9171 2.82353 14.1064 3.10588 14.3905Z"
          :fill="color"
        />
        <path
          d="M9.78821 15.6213C9.88233 15.716 10.0706 15.716 10.2588 15.716C13.647 14.6746 16 11.5503 16 8.04734C16 5.9645 15.1529 3.88166 13.647 2.36686C13.3647 2.08284 13.1764 1.89349 12.8941 1.60947L13.647 0.946746C13.8353 0.757396 13.8353 0.568047 13.7412 0.378698C13.647 0.0946745 13.4588 0 13.1764 0H10.0706C9.78821 0 9.50586 0.284024 9.50586 0.568047V3.69231C9.50586 3.88166 9.59998 4.07101 9.78821 4.16568C9.97645 4.26035 10.2588 4.16568 10.3529 4.07101L11.2941 3.21894C11.5764 3.40828 11.8588 3.69231 12.1412 3.88166C13.5529 5.30177 14.1176 7.38462 13.7412 9.27811C13.2706 11.2663 11.8588 12.8757 9.97645 13.5385C9.78821 13.6331 9.59998 13.8225 9.59998 14.0118V15.1479C9.59998 15.3373 9.69409 15.5266 9.78821 15.6213Z"
          :fill="color"
        />
        <!-- <title>{{ title }}</title> -->
      </svg>
    </el-tooltip>
  </span>
</template>

<script>
export default {
  props: {
    color: {
      default: "#33A02C"
    },
    title: {
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped></style>
